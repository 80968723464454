import { FormControl } from '@angular/forms';

import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { CachedProductsAndCategories, CoreSchema, Product, StrictDictionary } from '@typings';

import { ColorSection } from './colors';
import { Image } from './images';
import { SortOrderInput } from './sort';

export type Menu = CoreSchema.Menu;
export type MenuElement = CoreSchema.MenuElement;
export type MenuSection = CoreSchema.MenuSection;
export type MenuItem = CoreSchema.MenuItem;
export type MenuType = CoreSchema.MenuType;
export type OnlineMenu = CoreSchema.OnlineMenu;
export type MenuTypeStrict = StrictDictionary<MenuType, MenuType>;
export type MenuTypeString = StrictDictionary<string, MenuType>;
export type MenuCreateInput = CoreSchema.MenuCreateInput;
export type MenuStructureInput = CoreSchema.MenuStructureInput;
export type MenuUpdateInput = CoreSchema.MenuUpdateInput;
export type MenuSectionCreateInput = CoreSchema.MenuSectionCreateInput;
export type MenuSectionUpdateInput = CoreSchema.MenuSectionUpdateInput;
export type MenuSectionPatchUpdateInput = CoreSchema.MenuSectionPatchUpdateInput;
export type MenuSectionPositionUpdateInput = CoreSchema.MenuSectionPositionUpdateInput;
export type MenuItemInput = CoreSchema.MenuItemInput;
export type MenuFilterInput = CoreSchema.MenuFilterInput;
export type MenuElementFilterInput = CoreSchema.MenuElementFilterInput;
export type MenuPage = CoreSchema.MenuPage;

export type MenusRc = CoreSchema.MenusResult;
export type MenuRc = CoreSchema.MenuRc;
export type MenuElementRc = CoreSchema.MenuElementRc;
export type MenuRcElementFilterInput = CoreSchema.MenuRcElementFilterInput;
export type MenuRcFilterInput = CoreSchema.MenuRcFilterInput;
export type MenuRcStructureInput = CoreSchema.MenuRcStructureInput;
export type SectionUpdateInput = CoreSchema.SectionUpdateInput;
export type UpdateItemInput = CoreSchema.UpdateItemInput;
export type SectionRc = CoreSchema.SectionRc;
export type itemRc = CoreSchema.ItemRc;
export type UpdateMenuInput = CoreSchema.UpdateMenuInput;

export type MutationCreateMenuArgs = CoreSchema.MutationCreateMenuArgs;
export type MutationDeleteMenuArgs = CoreSchema.MutationDeleteMenuArgs;
export type MutationUpdateMenuArgs = CoreSchema.MutationUpdateMenuArgs;
export type MutationDuplicateMenuArgs = CoreSchema.MutationDuplicateMenuArgs;
export type MutationCreateMenuSectionArgs = CoreSchema.MutationCreateMenuSectionArgs;
export type MutationCreateMenuStructureArgs = CoreSchema.MutationCreateMenuStructureArgs;
export type MutationAddMenuStructureArgs = CoreSchema.MutationAddMenuStructureArgs;
export type MutationCreateMenuItemArgs = CoreSchema.MutationCreateMenuItemArgs;
export type MutationCreateMenuItemsListArgs = CoreSchema.MutationCreateMenuItemsListArgs;
export type MutationDeleteMenuItemArgs = CoreSchema.MutationDeleteMenuItemArgs;
export type MutationDeleteMenuSectionArgs = CoreSchema.MutationDeleteMenuSectionArgs;
export type MutationUpdateMenuItemArgs = CoreSchema.MutationUpdateMenuItemArgs;
export type MutationUpdateMenuSectionArgs = CoreSchema.MutationUpdateMenuSectionArgs;
export type MutationUpdateMenuItemsListArgs = CoreSchema.MutationUpdateMenuItemsListArgs;
export type MutationUpdateMenuSectionsListArgs = CoreSchema.MutationUpdateMenuSectionsListArgs;
export type MutationOnlineMenuSetActiveStateArgs = CoreSchema.MutationOnlineMenuSetActiveStateArgs;
export type MutationOnlineMenuSectionSetImageArgs = CoreSchema.MutationOnlineMenuSectionSetImageArgs;
export type MutationUpdateMenuSectionV2PatchArgs = CoreSchema.MutationUpdateMenuSectionV2PatchArgs;
export type MutationChangeMenuSectionPositionArgs = CoreSchema.MutationChangeMenuSectionPositionArgs;

export type MutationCreateMenuRcArgs = CoreSchema.MutationCreateMenuRcArgs;
export type MutationAddMenuRcArgs = CoreSchema.MutationAddMenuRcArgs;
export type MutationDeleteMenuRcArgs = CoreSchema.MutationDeleteMenuRcArgs;
export type MutationUpdateMenuRcArgs = CoreSchema.MutationUpdateMenuRcArgs;
export type MutationCreateSectionRcArgs = CoreSchema.MutationCreateSectionRcArgs;
export type MutationUpdateSectionRcArgs = CoreSchema.MutationUpdateSectionRcArgs;
export type MutationDeleteSectionRcArgs = CoreSchema.MutationDeleteSectionRcArgs;
export type MutationChangeSectionRcPositionArgs = CoreSchema.MutationChangeSectionRcPositionArgs;
export type MutationCreateItemsRcListArgs = CoreSchema.MutationCreateItemsRcListArgs;
export type MutationUpdateItemRcArgs = CoreSchema.MutationUpdateItemRcArgs;
export type MutationDeleteItemRcArgs = CoreSchema.MutationDeleteItemRcArgs;
export type MutationChangeItemRcPositionArgs = CoreSchema.MutationChangeItemRcPositionArgs;
export type MutationDuplicateMenuRcArgs = CoreSchema.MutationDuplicateMenuRcArgs;
export type MutationMoveElementsArgs = CoreSchema.MutationMoveElementsArgs;
export type QueryMenuRcElementArgs = CoreSchema.QueryMenuRcElementArgs;
export type QueryMenuRcRootElementsArgs = CoreSchema.QueryMenuRcRootElementsArgs;
export type QueryMenuRcImmediateDescendantsArgs = CoreSchema.QueryMenuRcImmediateDescendantsArgs;

export type QueryAllMenusArgs = CoreSchema.QueryAllMenusArgs;
export type QueryMenuArgs = CoreSchema.QueryMenuArgs;
export type QueryAllMenusByStoreIdsArgs = CoreSchema.QueryAllMenusByStoreIdsArgs;
export type QueryAllMenusByStoreIdsPageableArgs = CoreSchema.QueryAllMenusByStoreIdsPageableArgs;
export type QueryAllRcMenusByStoreIdsPageableArgs = CoreSchema.QueryMenusRcArgs;
export type QueryMenuSectionsBySectionArgs = CoreSchema.QueryMenuSectionsBySectionArgs;
export type QueryMenuSectionArgs = CoreSchema.QueryMenuSectionArgs;
export type QueryFindMenuElementsArgs = CoreSchema.QueryFindMenuElementsArgs;
export type QueryMenuRcElementsArgs = CoreSchema.QueryMenuRcElementsArgs;
export type QueryAllMenuItemsBySectionArgs = CoreSchema.QueryAllMenuItemsBySectionArgs;
export type QueryMenuItemArgs = CoreSchema.QueryMenuItemArgs;
export type QueryMenuRcArgs = CoreSchema.QueryMenuRcArgs;

export type InfiniteMenusFilters = MenuFilterInput & { storeIds: string[] } & { sort: SortOrderInput[] };

export type MenuForm = {
  name: FormControl<string>;
  storeId: FormControl<string | null>;
  structure?: FormControl<AutocompleteOption<CachedProductsAndCategories>[]>;
  useStructure?: FormControl<boolean>;
};

export type MenuSectionForm = {
  name: FormControl<string>;
  image: FormControl<Image | null>;
  colorSection: FormControl<ColorSection | null>;
  parentSection: FormControl<string | undefined>;
};

export type MenuDuplicateForm = {
  storeIds: FormControl<string[]>;
};

export type MenuSubectionForm = {
  name: FormControl<string>;
  parentSection: FormControl<string>;
};

export type MenuItemsForm = {
  section: FormControl<string | undefined>;
  structure: FormControl<AutocompleteOption<CachedProductsAndCategories>[]>;
  useStructure: FormControl<boolean>;
};

export type EditItemForm = {
  name: FormControl<string>;
  product: FormControl<AutocompleteOption<Product> | null>;
  subsection: FormControl<string | undefined>;
};

export type MenuOnlineForm = {
  active: FormControl<boolean>;
  link: FormControl<string>;
};

export type MenuLoaderFilters = MenuFilterInput & { sort?: SortOrderInput[] } & { storeIds?: string[] };

export const STATE_MENU_STRING = {
  ON: 'Включено',
  OFF: 'Выключено',
};

export const STATE_MENU = {
  ON: 'ON',
  OFF: 'OFF',
};
