import { gql } from 'apollo-angular';

export const queryCurrencyList = gql`
  query currency {
    __type(name: "Currency") {
      enumValues {
        name
        description
      }
    }
  }
`;

export const queryOrganizationSitesByAccount = gql`
  query organizationSitesByAccount($accountId: UUID!, $filter: OrganizationFilterInput, $pageRequestInput: PageRequestInput!) {
    organizationSitesByAccount(accountId: $accountId, filter: $filter, pageRequestInput: $pageRequestInput) {
      pageNumber
      pageSize
      total
      totalPages
      content {
        id
        currency
        name
        shortId
        status
        timezone
      }
    }
  }
`;

export const queryOrganizationSite = gql`
  query organizationSite($id: UUID!) {
    organizationSite(id: $id) {
      id
      currency
      name
      shortId
      status
      timezone
    }
  }
`;

export const mutationCreateOrganizationSite = gql`
  mutation createOrganizationSite($accountId: UUID!) {
    createOrganizationSite(accountId: $accountId) {
      status {
        errorMessage
        result
      }
      createdOrganization {
        id
        currency
        name
        shortId
        status
        timezone
      }
    }
  }
`;

export const mutationUpdateOrganizationSite = gql`
  mutation updateOrganizationSite($input: UpdateOrganizationSiteInput!) {
    updateOrganizationSite(input: $input) {
      status {
        errorMessage
        result
      }
      updatedOrganization {
        id
        currency
        name
        shortId
        status
        timezone
      }
    }
  }
`;
