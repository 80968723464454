import { ActionsComponent } from '@app/shared/component/actions/actions.component';
import { ROUTE_CREATE_NEW, ROUTE_IMPORT } from '@constants/navigation';

export enum MarketingRoute {
  groups = 'groups',
  clients = 'clients',
  programs = 'programs',
}

export enum MarketingRouteName {
  groups = 'Группы клиентов',
  clients = 'Клиенты',
  programs = 'Акции',
}

export enum MarketingRouteParam {
  groupId = 'idGroup',
  clientId = 'idClient',
  programId = 'idProgram',
}

export const MARKETING_ITEM_CLIENTS = {
  title: MarketingRouteName.clients,
  route: MarketingRoute.clients,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новый клиент',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Клиент',
      route: MarketingRouteParam.clientId,
    },
    {
      title: 'Импорт',
      route: ROUTE_IMPORT,
    },
  ],
};

export const MARKETING_ITEM_GROUPS = {
  title: MarketingRouteName.groups,
  route: MarketingRoute.groups,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новая группа клиентов',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Группа клиентов',
      route: MarketingRouteParam.groupId,
    },
  ],
};

export const MARKETING_ITEM_PROGRAMS = {
  title: MarketingRouteName.programs,
  route: MarketingRoute.programs,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новая акция',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Акция',
      route: MarketingRouteParam.programId,
    },
  ],
};

export const MARKETING_CHILD_ITEMS = [MARKETING_ITEM_CLIENTS, MARKETING_ITEM_GROUPS, MARKETING_ITEM_PROGRAMS];
