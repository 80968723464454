import { generateExportDate } from '@utils';

export enum ChartDate {
  DAY = 'За день',
  WEEK = 'За неделю',
  MONTH = 'За месяц',
}

export enum ChartDateRangeType {
  HOUR = 'По часам',
  DAY = 'По дням',
  MONTH = 'По месяцам',
}

export const ChartWeekDay = new Map([
  [1, 'Пн'],
  [2, 'Вт'],
  [3, 'Ср'],
  [4, 'Чт'],
  [5, 'Пт'],
  [6, 'Сб'],
  [7, 'Вс'],
]);

// FIXME: use `keyof CoreSchema.AnalyticsChartReport` instead, but need to find how to work with values
export enum ChartReportType {
  PROFIT = 'profit',
  REVENUE = 'revenue',
  AVERAGE_CHECK = 'averageCheck',
  GUESTS_COUNT = 'guestsCount',
  CHECKS_COUNT = 'checksCount',
}

export const chartReportTypeSalesByHour = {
  profit: 'averageHourlyProfit',
  revenue: 'averageHourlyRevenue',
  averageCheck: 'averageHourlyCheck',
  guestsCount: 'totalHourlyGuestsCount',
  checksCount: 'totalHourlyChecksCount',
};

export const chartReportTypeSalesByWeek = {
  profit: 'averageWeeklyProfit',
  revenue: 'averageWeeklyRevenue',
  averageCheck: 'averageWeeklyCheck',
  guestsCount: 'totalWeeklyGuestsCount',
  checksCount: 'totalWeeklyChecksCount',
};

export enum BarChartType {
  BY_HOUR = 'BY_HOUR',
  BY_WEEK = 'BY_WEEK',
}

export interface BarChartTypeItem {
  name: string;
  value: BarChartType;
}

export const barChartTypes = [
  {
    name: 'По часам',
    value: BarChartType.BY_HOUR,
  },
  {
    name: 'По дням недели',
    value: BarChartType.BY_WEEK,
  },
];

const exportFileExtension = '.xls';

export const exportUrlByReportType = {
  OVERVIEW: {
    url: 'report/salesOverviewByOrg',
    fileName: 'Обзор_продаж_по_организациям_отчет_' + generateExportDate() + exportFileExtension,
  },
  OVERVIEW_STOCK_UNIT: {
    url: 'report/stockUnitSalesOverviewByOrg',
    fileName: 'Обзор_продаж_по_товару_отчет_' + generateExportDate() + exportFileExtension,
  },
  ABC_REPORT: {
    url: 'report/abcV2',
    fileName: 'Абс_отчет_' + generateExportDate() + exportFileExtension,
  },
  STOCK_UNIT_WRITTEN_OFF_REPORT: {
    url: 'report/warehouse/stockUnitWrittenOffReport',
    fileName: 'Склад_отчет_по_сырью_' + generateExportDate() + exportFileExtension,
  },
  ORDERS: {
    url: 'report/ordersReport',
    fileName: 'Отчет_по_заказам_' + generateExportDate() + exportFileExtension,
  },
  TECH_CARD_REPORT: {
    url: 'report/techCardReport',
    fileName: 'Отчет_по_техкарте_' + generateExportDate() + '.pdf',
  },
  EMPLOYEE: {
    url: 'report/employeeReport',
    fileName: 'Отчет_по_сотрудникам_' + generateExportDate() + exportFileExtension,
  },
  LEAVING: {
    url: 'report/warehouse/leavingDocumentReport',
    fileName: 'Склад_отчет_по_списанию_' + generateExportDate() + exportFileExtension,
  },
  ALL_LEAVING: {
    url: 'report/warehouse/allLeavingDocumentReport',
    fileName: 'Склад_отчет_по_списанию_' + generateExportDate() + exportFileExtension,
  },
  ALL_LEAVING_V2: {
    url: 'report/warehouse/allLeavingDocumentReportV2',
    fileName: 'Склад_отчет_по_списанию_' + generateExportDate() + exportFileExtension,
  },
  MOVE: {
    url: 'report/warehouse/movingDocumentReport',
    fileName: 'Склад_отчет_по_перемещению_' + generateExportDate() + exportFileExtension,
  },
  ALL_MOVE: {
    url: 'report/warehouse/allMovingDocumentReport',
    fileName: 'Склад_отчет_по_перемещению_' + generateExportDate() + exportFileExtension,
  },
  ALL_MOVE_V2: {
    url: 'report/warehouse/allMovingDocumentReportV2',
    fileName: 'Склад_отчет_по_перемещению_' + generateExportDate() + exportFileExtension,
  },
  REMAINDER: {
    url: 'report/warehouse/remaindersReport',
    fileName: 'Склад_отчет_по_остаткам_' + generateExportDate() + exportFileExtension,
  },
  REMAINDER_V2: {
    url: 'report/warehouse/remaindersReportV2',
    fileName: 'Склад_отчет_по_остаткам_' + generateExportDate() + exportFileExtension,
  },
  SUPPLIERS: {
    url: 'report/warehouse/suppliersReport',
    fileName: 'Склад_отчет_по_поставщикам_' + generateExportDate() + exportFileExtension,
  },
  INVENTORY: {
    url: 'report/warehouse/inventoryReport',
    fileName: 'Склад_отчет_по_инвентаризации_' + generateExportDate() + exportFileExtension,
  },
  ALL_INVENTORY: {
    url: 'report/warehouse/allInventoryDocumentReport',
    fileName: 'Склад_отчет_по_инвентаризации_' + generateExportDate() + exportFileExtension,
  },
  ALL_INVENTORY_V2: {
    url: 'report/warehouse/allInventoryDocumentReportV2',
    fileName: 'Склад_отчет_по_инвентаризации_' + generateExportDate() + exportFileExtension,
  },
  INVENTORY_LIST: {
    url: 'report/warehouse/inventoryListReport',
    fileName: 'Склад_инвентаризационный_лист_' + generateExportDate() + exportFileExtension,
  },
  ARRIVAL: {
    url: 'report/warehouse/arrivalDocumentReport',
    fileName: 'Склад_отчет_по_поставкам_' + generateExportDate() + exportFileExtension,
  },
  ALL_ARRIVAL: {
    url: 'report/warehouse/allArrivalDocumentReport',
    fileName: 'Склад_отчет_по_поставкам_' + generateExportDate() + exportFileExtension,
  },
  ALL_ARRIVAL_V2: {
    url: 'report/warehouse/allArrivalDocumentReportV2',
    fileName: 'Склад_отчет_по_поставкам_' + generateExportDate() + exportFileExtension,
  },
  PAYMENTS: {
    url: 'report/paymentsReport',
    fileName: 'Отчет_по_платежам_' + generateExportDate() + exportFileExtension,
  },
  CATALOG: {
    url: 'report/catalogReport',
    fileName: 'Отчет_по_каталогу_' + generateExportDate() + exportFileExtension,
  },
  LOYALTY: {
    url: 'report/loyalty',
    fileName: 'Отчет_по_лояльности_' + generateExportDate() + exportFileExtension,
  },
  LOYALTY_CLIENTS: {
    url: 'report/clientsReport',
    fileName: 'Отчет_по_лояльности_клиенты_' + generateExportDate() + exportFileExtension,
  },
  LOYALTY_CREDITS: {
    url: 'report/creditLoyaltyReport',
    fileName: 'Отчет_по_лояльности_бонусные_программы_начисление_' + generateExportDate() + exportFileExtension,
  },
  LOYALTY_DEBITS: {
    url: 'report/debitLoyaltyReport',
    fileName: 'Отчет_по_лояльности_бонусные_программы_списание_' + generateExportDate() + exportFileExtension,
  },
  LOYALTY_DISCOUNTS: {
    url: 'report/discountsReport',
    fileName: 'Отчет_по_лояльности_скидки_' + generateExportDate() + exportFileExtension,
  },
  PAY_SYSTEMS_REPORT: {
    url: 'report/paySystemReport',
    fileName: 'Отчет_по_способам_оплаты_' + generateExportDate() + exportFileExtension,
  },
  CATEGORY_REPORT: {
    url: 'report/categoryReport',
    fileName: 'Отчет_по_категориям_' + generateExportDate() + exportFileExtension,
  },
  PRODUCTS_REPORT: {
    url: 'report/productsReport',
    fileName: 'Отчет_по_товарам_' + generateExportDate() + exportFileExtension,
  },
  CLIENT_GROUPS: {
    url: 'report/customerGroupsReport',
    fileName: 'Отчет_по_группам_клиентов_' + generateExportDate() + exportFileExtension,
  },
  PRODUCTS_WITH_INGREDIENTS: {
    url: 'report/productsWithIngredients',
    fileName: 'Список_продуктов_с_составом_' + generateExportDate() + exportFileExtension,
  },
  PRODUCTS_WITHOUT_INGREDIENTS: {
    url: 'report/productsWithoutIngredients',
    fileName: 'Список_продуктов_' + generateExportDate() + exportFileExtension,
  },
  TABLES_REPORT: {
    url: 'report/tablesReport',
    fileName: 'Отчет_по_столам_' + generateExportDate() + exportFileExtension,
  },
};

export interface ExportUrlByReportType {
  url: string;
  fileName: string;
}
