import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FeatureEnableService } from '@services/shared';

import { BaseLayoutService } from './base-layout.service';

@Component({
  selector: 'nm-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseLayoutComponent {
  @Input() showHeader = true;
  @Input() showLogo = true;
  @Input() showFooterInfo = true;
  @Input() title = '';

  constructor(
    private featureEnableService: FeatureEnableService, // don't remove this import, it causes https://youtrack.nomia.dev/issue/ERPF-2232
    private route: ActivatedRoute,
    public layoutService: BaseLayoutService,
  ) {
    this.showHeader = route.snapshot.data.showHeader;
    this.showLogo = route.snapshot.data.showLogo;
    this.showFooterInfo = route.snapshot.data.showFooterInfo;
    this.title = route.snapshot.data.title;

    this.layoutService.settings$.subscribe((settings) => {
      if (!settings) {
        return;
      }
      this.showLogo = settings.showLogo;
      this.showHeader = settings.showHeader;
      this.showFooterInfo = settings.showFooterInfo;
      this.title = settings.title;
    });
  }
}
