import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, take } from 'rxjs';

import { SubscriptionsStorage } from '@app/modules/settings/services';
import { RootNavigationRoute, SettingsRoute, SettingsSubscriptionsRoute } from '@constants';
import { SessionStorage } from '@services/api';
import { RedirectService } from '@services/shared';
import {
  Addon,
  Bill,
  BillPage,
  DeviceUsage,
  MonetizationOrder,
  OrderAddonInput,
  PageRequestInput,
  PaymentReminderType,
  ServicePlanUsageSummary,
  ServicePlanV2,
  Subscriber,
  SubscriptionDeviceType,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  subscriber: Subscriber;
  servicePlanUsageSummary: ServicePlanUsageSummary;
  recurrentBill: Bill;

  licenceOrder: MonetizationOrder;

  constructor(
    private router: Router,
    private subscriptionsStorage: SubscriptionsStorage,
    private sessionStorage: SessionStorage,
    private redirectService: RedirectService,
  ) {}

  toList(): void {
    this.router.navigateByUrl(
      `${this.sessionStorage.getOrgId()}/${RootNavigationRoute.settings}/${SettingsRoute.subscriptions}/${
        SettingsSubscriptionsRoute.invoices
      }`,
    );
  }

  init(subscriber: Subscriber, servicePlanUsageSummary: ServicePlanUsageSummary, recurrentBill: Bill): void {
    this.subscriber = subscriber;
    this.servicePlanUsageSummary = servicePlanUsageSummary;
    this.recurrentBill = recurrentBill;
  }

  getServicePlanPrice(): number {
    return Number(this.subscriber?.servicePlan?.price?.amountValue || '0');
  }

  getRecurrentBillAmount(): number {
    return parseFloat(this.recurrentBill?.totalAmount?.amountValue || '0');
  }

  getAddonsPrice(): number {
    return (this.subscriber?.addonsSubscriptions || []).reduce((acc, addon) => {
      return acc + Number(addon?.addon?.price?.amountValue || '0');
    }, 0);
  }

  getDeviceUsagesCount(deviceType: SubscriptionDeviceType): number {
    return (this.subscriber.deviceUsages || [])
      .filter((usage): usage is DeviceUsage => !!usage && usage.deviceType === deviceType)
      .reduce((acc, usage) => acc + usage.used, 0);
  }

  getNextPeriodEnd(): string {
    if (!this.subscriber.validUntil) {
      return '';
    }

    const nextPeriodEnd = new Date(this.subscriber.validUntil);
    nextPeriodEnd.setMonth(nextPeriodEnd.getMonth() + 1);

    return nextPeriodEnd.toISOString();
  }

  getPaymentReminder(): PaymentReminderType {
    const { remainingDays } = this.servicePlanUsageSummary;

    if (typeof remainingDays !== 'number') {
      return 'none';
    }
    if (remainingDays <= 3) {
      return 'error';
    }
    if (remainingDays <= 10) {
      return 'warn';
    }
    return 'none';
  }

  getSubscriber(): Observable<Subscriber> {
    return this.subscriptionsStorage.getSubscriber().pipe(map((res) => res.data.subscriber));
  }

  getServicePlanUsageSummary(): Observable<ServicePlanUsageSummary> {
    return this.subscriptionsStorage.getServicePlanUsageSummary().pipe(map((res) => res.data.servicePlanUsageSummary));
  }

  getBillList(pageRequest: PageRequestInput): Observable<BillPage> {
    return this.subscriptionsStorage.getBillList({ pageRequest }).pipe(map((res) => res.data.bills));
  }

  getBill(id: string): Observable<Bill> {
    return this.subscriptionsStorage.getBill({ billId: id }).pipe(map((res) => res.data.bill));
  }

  getServicePlanOrder(servicePlanId: string): Observable<MonetizationOrder | null> {
    return this.subscriptionsStorage.orderServicePlan({ servicePlanId }).pipe(
      take(1),
      map((res) => res.data?.orderServicePlan?.output || null),
    );
  }

  getBillOrder(billId: string): Observable<MonetizationOrder | null> {
    return this.subscriptionsStorage.orderRecurringBill({ billId }).pipe(
      take(1),
      map((res) => res.data?.orderRecurringBill?.output || null),
    );
  }

  getAddonOrder(addons: OrderAddonInput[]): Observable<MonetizationOrder | null> {
    return this.subscriptionsStorage.orderAddon({ addonRequests: addons }).pipe(
      take(1),
      map((res) => res.data?.orderAddon?.output || null),
    );
  }

  payOrder(orderId: string): void {
    this.subscriptionsStorage
      .payOrder({ orderId })
      .pipe(take(1))
      .subscribe((res) => {
        if (res.data?.payOrder?.output) {
          const { paymentUrl } = res.data?.payOrder?.output;
          window.open(`${paymentUrl}`, '_self');
          this.redirectService.redirectToInvoices(this.sessionStorage.getOrgId()!);
        } else {
          throw Error('Ошибка при оплате');
        }
      });
  }

  getServicePlanList(): Observable<ServicePlanV2[]> {
    return this.subscriptionsStorage.getServicePlanList().pipe(map((res) => res.data.servicePlansV2.content));
  }

  getAddonsList(): Observable<Addon[]> {
    return this.subscriptionsStorage.getAddonList({ pageRequest: { page: 0, size: 1000 } }).pipe(map((res) => res.data.addons.content));
  }
}
