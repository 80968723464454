import { Injectable } from '@angular/core';

import { NumberSeparatePipe } from '@app/shared/pipe/number-separate.pipe';
import { UtilsService } from '@core/service/utils.service';
import { ValidationErrorsService } from '@core/service/validation-errors.service';

export type NumberFormatOpts = {
  isDecimal: boolean;
  maxFractionalDigits: number;
  maxIntegerDigits: number;
  separateNumbers: boolean; // 10000000.01 => 10 000 000.01
};

@Injectable({
  providedIn: 'root',
})
export class NumberFormatService {
  constructor(
    private validationErrorsService: ValidationErrorsService,
    private utilsService: UtilsService,
    private numberSeparatePipe: NumberSeparatePipe,
  ) {}

  formatNumber(value?: string | number | null, options: Partial<NumberFormatOpts> = {}): string | null {
    if (value === null || value === undefined) {
      return null;
    }

    const valueStr = String(value);

    if (!valueStr) {
      return null;
    }

    const { isDecimal, maxFractionalDigits, maxIntegerDigits, separateNumbers } = options;

    let validated = this.validationErrorsService.checkInputNumbersV2(valueStr, {
      decimal: isDecimal,
      fractional: maxFractionalDigits,
    });

    if (maxIntegerDigits) {
      validated = this.utilsService.truncateInteger(validated, maxIntegerDigits);
    }

    if (separateNumbers) {
      validated = this.numberSeparatePipe.transform(validated);
    }

    return validated;
  }

  strToNumber(number?: string | null, fallback: number = 0): number {
    const num = Number(String(number).split(' ').join(''));

    if (isNaN(num)) {
      return fallback;
    }

    return num;
  }
}
