import { FormControl } from '@angular/forms';

import { CoreSchema, StrictDictionary } from '@typings';

export type Organization = CoreSchema.Organization;

export type OrganizationPage = CoreSchema.OrganizationPage;

export type OrganizationFilterInput = CoreSchema.OrganizationFilterInput;

export type QueryAllOrganizationsPageableArgs = CoreSchema.QueryAllOrganizationsPageableArgs;

export type UpdateOrganizationInput = CoreSchema.UpdateOrganizationInput;

export type CreateOrganizationInput = CoreSchema.CreateOrganizationInput;

export type MutationCreateOrganizationArgs = CoreSchema.MutationCreateOrganizationArgs;
export type MutationUpdateOrganizationArgs = CoreSchema.MutationUpdateOrganizationArgs;
export type QueryOrganizationArgs = CoreSchema.QueryOrganizationArgs;
export type Currency = CoreSchema.Currency;
export type CurrencyStrict = StrictDictionary<Currency>;
export type CurrencyString = StrictDictionary<string>;

// NEW ORGANIZATION
export type OrganizationSite = CoreSchema.OrganizationSite;
export type OrganizationStatus = CoreSchema.OrganizationStatus;
export type OrganizationSitePage = CoreSchema.OrganizationSitePage;
export type CreateOrganizationSiteResponse = CoreSchema.CreateOrganizationSiteResponse;
export type UpdateOrganizationSiteInput = CoreSchema.UpdateOrganizationSiteInput;
export type UpdateOrganizationSiteResponse = CoreSchema.UpdateOrganizationSiteResponse;
export type MutationCreateOrganizationSiteArgs = CoreSchema.MutationCreateOrganizationSiteArgs;
export type MutationUpdateOrganizationSiteArgs = CoreSchema.MutationUpdateOrganizationSiteArgs;
export type QueryOrganizationSiteArgs = CoreSchema.QueryOrganizationSiteArgs;
export type QueryOrganizationSitesByAccountArgs = CoreSchema.QueryOrganizationSitesByAccountArgs;

export type OrganizationForm = {
  name: FormControl<string>;
  currency: FormControl<string>;
  language: FormControl<string>;
};

export type __EnumValue = {
  name: string;
  description: string;
};

export type __Type = {
  enumValues: __EnumValue[];
};

export type CurrencyEnum = {
  __type: __Type;
};

export const CURRENCY: CurrencyStrict = {
  RUB: 'RUB',
};

export const CURRENCY_SHORT: CurrencyString = {
  AED: 'dh',
  AFN: 'Af.',
  ALL: 'Lek',
  AMD: 'Dram',
  ANG: 'NAf.',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'Afl.',
  AZN: '₼',
  BAM: 'BAM',
  BBD: '$',
  BDT: '৳',
  BGN: 'lev',
  BHD: 'din',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: 'Bs',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'p.',
  BZD: '$',
  CAD: '$',
  CDF: 'FrCD',
  CHE: '€',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: '$',
  CUP: '$',
  CVE: 'CVE',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr.',
  DOP: 'RD$',
  DZD: 'din',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Birr',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: 'GEL',
  GHS: 'GHS',
  GIP: '£',
  GMD: 'GMD',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HTG: 'HTG',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  IQD: 'din',
  IRR: 'Rial',
  ISK: 'kr',
  JMD: '$',
  JOD: 'din',
  JPY: '¥',
  KES: 'Ksh',
  KGS: 'KGS',
  KHR: 'Riel',
  KMF: 'CF',
  KPW: '₩KP',
  KRW: '₩',
  KWD: 'din',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: 'Rs',
  LRD: '$',
  LSL: 'LSL',
  LYD: 'din',
  MAD: 'dh',
  MDL: 'MDL',
  MGA: 'Ar',
  MKD: 'din',
  MMK: 'MMK',
  MNT: '₮',
  MOP: 'din',
  MRU: 'MRU',
  MUR: 'MUR',
  MVR: 'Rf',
  MWK: 'MWK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MTn',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: 'Rs',
  NZD: '$',
  OMR: 'Rial',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'PGK',
  PHP: '₱',
  PKR: 'Rs',
  PLN: 'zł',
  PYG: 'Gs.',
  QAR: 'Rial',
  RON: 'RON',
  RSD: 'din',
  RUB: '₽',
  RWF: 'RF',
  SAR: 'SAR',
  SBD: '$',
  SCR: 'SCR',
  SDG: '£',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SLE: 'SLE',
  SLL: 'SLL',
  SOS: 'SOS',
  SRD: '$',
  SSP: '£',
  STN: 'Db',
  SVC: 'SVC',
  SYP: '£',
  SZL: 'SZL',
  THB: '฿',
  TJS: 'Som',
  TMT: 'm',
  TND: 'din',
  TOP: 'T$',
  TRY: '₺',
  TTD: '$',
  TWD: '$',
  TZS: 'TSh',
  UAH: 'грн.',
  UGX: 'UGX',
  USD: '$',
  USN: '$',
  UYI: 'UYI',
  UYU: '$',
  UYW: 'UYW',
  UZS: 'so‘m',
  VED: 'VED',
  VES: 'VES',
  VND: '₫',
  VUV: 'VUV',
  WST: 'WST',
  XAF: 'FCFA',
  XCD: '$',
  XDR: 'XDR',
  XOF: 'CFA',
  XPF: 'FCFP',
  XSU: 'XSU',
  XUA: 'XUA',
  YER: 'Rial',
  ZAR: 'R',
  ZMW: 'ZMW',
  ZWL: '$',
};

export const CURRENCY_LONG: CurrencyString = {
  AED: 'Дирхам ОАЭ, dh',
  AFN: 'Афганский афгани, Af.',
  ALL: 'Албанский лек, Lek',
  AMD: 'Армянский драм, Dram',
  ANG: 'Гульден Нидерландских Антил, NAf.',
  AOA: 'Ангольская кванза, Kz',
  ARS: 'Аргентинское песо, $',
  AUD: 'Австралийский доллар, $',
  AWG: 'Арубский флорин / Арубский гульден, Afl.',
  AZN: 'Азербайджанский манат, ₼',
  BAM: 'Конвертируемая марка, BAM',
  BBD: 'Барбадосский доллар, $',
  BDT: 'Бангладешская така, ৳',
  BGN: 'Болгарский лев, lev',
  BHD: 'Бахрейнский динар, din',
  BIF: 'Бурундийский франк, FBu',
  BMD: 'Бермудский доллар, $',
  BND: 'Брунейский доллар, $',
  BOB: 'Боливийский боливиано, Bs',
  BOV: 'Боливиано, BOV',
  BRL: 'Бразильский реал, R$',
  BSD: 'Багамский доллар, $',
  BTN: 'Бутанский нгултрум, Nu.',
  BWP: 'Ботсванская пула, P',
  BYN: 'Белорусский рубль, р.',
  BZD: 'Белизский доллар, $',
  CAD: 'Канадский доллар, $',
  CDF: 'Конголезский франк, FrCD',
  CHE: 'WIR Евро, €',
  CHF: 'Швейцарский франк, CHF',
  CHW: 'WIR франк, CHW',
  CLF: 'Условная расчётная единица Чили, CLF',
  CLP: 'Чилийское песо, $',
  CNY: 'Китайский юань, ¥',
  COP: 'Колумбийское песо, $',
  COU: 'Расчётная единица Колумбии, COU',
  CRC: 'Костариканский колон, ₡',
  CUC: 'Кубинское конвертируемое песо, $',
  CUP: 'Кубинское песо, $',
  CVE: 'Эскудо Кабо-Верде, CVE',
  CZK: 'Чешская крона, Kč',
  DJF: 'Джибутийский франк, Fdj',
  DKK: 'Датская крона, kr.',
  DOP: 'Доминиканское песо, RD$',
  DZD: 'Алжирский динар, din',
  EGP: 'Египетский фунт, £',
  ERN: 'Эритрейская накфа, Nfk',
  ETB: 'Эфиопский быр, Birr',
  EUR: 'Евро, €',
  FJD: 'Доллар Фиджи, $',
  FKP: 'Фунт Фолклендских островов, £',
  GBP: 'Фунт стерлингов Соединенного Королевства, £',
  GEL: 'Грузинский лари, GEL',
  GHS: 'Ганский седи, GHS',
  GIP: 'Гибралтарский фунт, £',
  GMD: 'Гамбийский даласи, GMD',
  GNF: 'Гвинейский франк, FG',
  GTQ: 'Гватемальский кетцаль, Q',
  GYD: 'Гайанский доллар, $',
  HKD: 'Гонконгский доллар, $',
  HNL: 'Гондурасская лемпира, L',
  HTG: 'Гаитянский гурд, HTG',
  HUF: 'Венгерский форинт, Ft',
  IDR: 'Индонезийская рупия, Rp',
  ILS: 'Израильский новый шекель, ₪',
  INR: 'Индийская рупия, ₹',
  IQD: 'Иракский динар, din',
  IRR: 'Иранский риал, Rial',
  ISK: 'Исландская крона, kr',
  JMD: 'Ямайский доллар, $',
  JOD: 'Иорданский динар, din',
  JPY: 'Японская иена, ¥',
  KES: 'Кенийский шиллинг, Ksh',
  KGS: 'Киргизский сом, KGS',
  KHR: 'Камбоджийский риель, Riel',
  KMF: 'Коморский франк, CF',
  KPW: 'Северокорейская вона, ₩KP',
  KRW: 'Южнокорейская вона, ₩',
  KWD: 'Кувейтский динар, din',
  KYD: 'Доллар Каймановых Островов, $',
  KZT: 'Казахский тенге, ₸',
  LAK: 'Лаосский кип, ₭',
  LBP: 'Ливанский фунт, £',
  LKR: 'Шри-Ланкийская рупия, Rs',
  LRD: 'Либерийский доллар, $',
  LSL: 'Лоти Лесото, LSL',
  LYD: 'Ливийский динар, din',
  MAD: 'Марокканский дирхам, dh',
  MDL: 'Молдавский лей, MDL',
  MGA: 'Малагасийский ариари, Ar',
  MKD: 'Македонский денар, din',
  MMK: 'Кьят Мьянмы, MMK',
  MNT: 'Монгольский тугрик, ₮',
  MOP: 'Патака Макао, din',
  MRU: 'Мавританская угия, MRU',
  MUR: 'Маврикийская рупия, MUR',
  MVR: 'Мальдивская руфия, Rf',
  MWK: 'Малавийская квача, MWK',
  MXN: 'Мексиканское песо, $',
  MXV: 'Мексиканский Унидад де Инверсион, MXV',
  MYR: 'Малайзийский ринггит, RM',
  MZN: 'Мозамбикский метикал, MTn',
  NAD: 'Намибийский доллар, $',
  NGN: 'Нигерийская найра, ₦',
  NIO: 'Никарагуанская золотая кордоба, C$',
  NOK: 'Норвежская крона, kr',
  NPR: 'Непальская рупия, Rs',
  NZD: 'Новозеландский доллар, $',
  OMR: 'Оманский риал, Rial',
  PAB: 'Панамский бальбоа, B/.',
  PEN: 'Перуанский новый соль, S/.',
  PGK: 'Кина Папуа-Новой Гвинеи, PGK',
  PHP: 'Филиппинское песо, ₱',
  PKR: 'Пакистанская рупия, Rs',
  PLN: 'Польский злотый, zł',
  PYG: 'Парагвайский гуарани, Gs.',
  QAR: 'Катарский риал, Rial',
  RON: 'Румынский новый лей, RON',
  RSD: 'Сербский динар, din',
  RUB: 'Российский рубль, ₽',
  RWF: 'Руандийский франк, RF',
  SAR: 'Саудовский риял, SAR',
  SBD: 'Доллар Соломоновых Островов, $',
  SCR: 'Сейшельская рупия, SCR',
  SDG: 'Суданский фунт, £',
  SEK: 'Шведская крона, kr',
  SGD: 'Сингапурский доллар, $',
  SHP: 'Фунт Святой Елены, £',
  SLE: 'Леоне, SLE',
  SLL: 'Сьерра-Леонский леоне, SLL',
  SOS: 'Сомалийский шиллинг, SOS',
  SRD: 'Суринамский доллар, $',
  SSP: 'Южносуданский фунт, £',
  STN: 'Добра Сан-Томе и Принсипи, Db',
  SVC: 'Сальвадорский колон, SVC',
  SYP: 'Сирийский фунт, £',
  SZL: 'Свазилендский лилангени, SZL',
  THB: 'Таиландский бат, ฿',
  TJS: 'Таджикский сомони, Som',
  TMT: 'Туркменский манат, m',
  TND: 'Тунисский динар, din',
  TOP: 'Тонганская паанга, T$',
  TRY: 'Турецкая новая лира, ₺',
  TTD: 'Доллар Тринидада и Тобаго, $',
  TWD: 'Тайваньский новый доллар, $',
  TZS: 'Танзанийский шиллинг, TSh',
  UAH: 'Украинская гривна, грн.',
  UGX: 'Угандийский шиллинг, UGX',
  USD: 'Доллар США, $',
  USN: 'Доллар США на следующий день, $',
  UYI: 'Уругвайское песо en Unidades Indexadas, UYI',
  UYU: 'Уругвайское песо, $',
  UYW: 'Предварительный Юнидад, UYW',
  UZS: 'Узбекский сум, so‘m',
  VED: 'Суверенный боливар, VED',
  VES: 'Суверенный боливар, VES',
  VND: 'Вьетнамский донг, ₫',
  VUV: 'Вануатский вату, VUV',
  WST: 'Самоанская тала, WST',
  XAF: 'Франк КФА BEAC, FCFA',
  XCD: 'Восточно-карибский доллар, $',
  XDR: 'Специальные права заимствования, XDR',
  XOF: 'Франк КФА BCEAO, CFA',
  XPF: 'Франк КФП, FCFP',
  XSU: 'Сукре, XSU',
  XUA: 'Расчетная единица ADB, XUA',
  YER: 'Йеменский риал, Rial',
  ZAR: 'Южноафриканский рэнд, R',
  ZMW: 'Замбийская квача, ZMW',
  ZWL: 'Зимбабвийский доллар, $',
};
