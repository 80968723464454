import { CatalogSection } from '@typings';

export const rootCatalogSection: CatalogSection = {
  id: 'root',
  name: 'Корневая категория',
  catalog: {
    id: 'root',
    name: 'root',
    organization: {
      id: 'root',
    },
  },
  color: {
    name: '',
    code: '',
  },
  depthLevel: 0,
  isParent: false,
  labels: [],
  leftMargin: 0,
  modifierGroups: [],
  products: [],
  productsCount: '',
  rightMargin: 0,
};
