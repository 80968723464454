import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject } from 'rxjs';

import { Drop } from '@app/shared/directive/drag-and-drop/drag-and-drop.types';
import { DragAndDropListService } from '@app/shared/directive/drag-and-drop/drag-and-drop-list.service';
import { TableService } from '@app/shared/service/table.service';
import { BreakpointObserverService } from '@core/service';

import { sortColumn } from '../helper';
import { Column, ColumnConfig, HeaderCheckboxState, Row, SortEnum, TableConfig } from '../table.types';

@Component({
  selector: 'nm-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  providers: [DragAndDropListService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderComponent {
  @Input() config: TableConfig;
  @Input() headerConfig: TableConfig;
  @Input() rows: Row[];
  @Input() gridTemplateColumns: string = 'auto';
  @ViewChild('checkbox') checkbox: HTMLInputElement;

  @Output() headerClick = new EventEmitter<ColumnConfig>();
  @Output() selectHeader = new EventEmitter<boolean>();
  @Output() columnDragEnd = new EventEmitter<Drop<Column>>();

  sortEnum = SortEnum;
  headerChecked$ = new BehaviorSubject<HeaderCheckboxState>(false);
  isResizing = false;

  constructor(public bos: BreakpointObserverService, public tableService: TableService, private cdRef: ChangeDetectorRef) {}

  set headerChecked(checked: HeaderCheckboxState) {
    this.headerChecked$.next(checked);
  }

  get headerChecked(): HeaderCheckboxState {
    this.cdRef.detectChanges();
    return this.headerChecked$.getValue();
  }

  get isDropListDisabled(): boolean {
    return !this.config?.dragAndDropColumns;
  }

  onColumnDragEnd(drop: Drop<unknown>) {
    this.columnDragEnd.emit(drop as Drop<Column>);
  }

  onHeaderClick(column: ColumnConfig, resize: boolean) {
    if (!column.sortable || resize) {
      return;
    }

    this.config.columnsConfig.filter((col) => col.name !== column.name).forEach((col) => (col.sorting = null));

    column = sortColumn(column);
    this.headerClick.emit(column);
  }

  onSelectHeader(event: MatCheckboxChange) {
    if (this.headerChecked === 'indeterminate') {
      this.headerChecked = false;
      this.selectHeader.emit(false);
    } else {
      this.headerChecked = event.checked;
      this.selectHeader.emit(event.checked);
    }
  }

  checkBorder(index: number, isLeft: boolean = true) {
    if (!this.config.hasGroupHeader) {
      return false;
    }

    return isLeft
      ? (this.config.columnsConfig[index]?.group || this.config.columnsConfig[index - 1]?.hidden) &&
          (!this.config.columnsConfig[index - 1]?.group ||
            this.config.columnsConfig[index]?.group !== this.config.columnsConfig[index - 1]?.group)
      : (this.config.columnsConfig[index]?.group && !this.config.columnsConfig[index + 1]?.group) ||
          this.config.columnsConfig[index + 1]?.hidden;
  }

  getLeftForPinned(config: ColumnConfig[], index: number) {
    let left = 0;
    for (let j = 0; j < index; j++) {
      if (config[j].pinned) {
        const a: string | [string, string] = config[j].width!;
        const width = Array.isArray(a) ? a[0].slice(0, -2) : 0;

        left += Number(width);
      }
    }

    return 40 + left + 'px';
  }

  isPinned(config: TableConfig) {
    return config.columnsConfig.some((c) => c.pinned);
  }

  getGridColumn(index: number, group: string) {
    let config = [...this.headerConfig.columnsConfig];
    const groups = config.splice(0, index + 1).filter((f) => f.group && f.group !== group);
    let newIndex = 0;
    groups.forEach((g) => {
      const groupHeader = this.config.groupHeader?.find((h) => h.id === g.group);
      if (groupHeader) {
        newIndex = newIndex + groupHeader.group.length - 1;
      }
      if (g.hidden) {
        newIndex = newIndex - 1;
      }
    });

    [...this.headerConfig.columnsConfig].splice(0, index + 1).forEach((c) => {
      if (c.hidden) {
        newIndex = newIndex - 1;
      }
    });
    newIndex = newIndex + 2 + index;
    const len = this.config.groupHeader?.find((h) => h.id === group)?.group.length || 0;

    return newIndex + '/' + Number(newIndex + len);
  }

  doResize(index: number) {
    this.tableService.isResizing.next(true);
    const columns = document.getElementsByClassName('bar-column');
    for (let i = index; i < columns.length; i = i + this.config.columnsConfig.length) {
      columns[i].setAttribute('class', 'bar-column active');
    }
  }

  endResize() {
    this.tableService.isResizing.next(false);
    const columns = document.getElementsByClassName('bar-column');
    for (let i = 0; i < columns.length; i++) {
      columns[i].setAttribute('class', 'bar-column');
    }
  }

  onResize(event: MouseEvent, i: number) {
    const tableHeader = document.getElementById('table-header');
    const column = document.getElementById('column' + i)?.getBoundingClientRect();
    if (this.tableService.isResizing.value) {
      let res: number[] = [];
      let hiddenBefore = 0;
      let hiddenAfter = 0;
      let checkAfter = true;
      if (tableHeader && column) {
        this.config.columnsConfig.forEach((c, index) => {
          const col = document.getElementById('column' + index);
          if (col) {
            if (!c.hidden) {
              res.push(col.offsetWidth);
              if (index > i) {
                checkAfter = false;
              }
            }
            if (c.hidden && index < i) hiddenBefore = hiddenBefore + 1;
            if (c.hidden && index > i && checkAfter) hiddenAfter = hiddenAfter + 1;
          }
        });

        const change = event.clientX - column.right - 3;
        res[i - hiddenBefore] = column.width + change <= 64 ? 64 : column.width + change;

        if (res[i - hiddenBefore] !== 64) {
          const newGridTemplate = [40, ...res].map((c) => c.toString() + 'px').join(' ');
          this.tableService.gridTemplateColumns$.next(newGridTemplate);
          const rows = document.getElementsByClassName('inner');
          for (let j = 0; j < rows.length; j++) {
            rows[j].setAttribute('style', 'grid-template-columns: ' + newGridTemplate);
          }
        }
        event.preventDefault();
      }
    }
  }
}
