import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { MenuItemType } from '@app/shared/component/menu-item/menu-item.model';
import { DAY_OF_WEEK, DAY_OF_WEEK_STRING, DAY_OF_WEEK_STRING_SHORT } from '@constants/day-of-week';
import {
  BonusFormula,
  BonusFormulaStrict,
  BonusFormulaString,
  BonusProgramTypeStrict,
  DiscountFormula,
  DiscountFormulaStrict,
  DiscountFormulaString,
  ProgramActive,
  ProgramActiveStrict,
  ProgramActiveString,
  PromotionProgramSortFieldStrict,
  PromotionType,
  PromotionTypeStrict,
  PromotionTypeString,
  WeekDayNew,
} from '@typings';

export const BONUS_PROGRAM_TYPE: BonusProgramTypeStrict = {
  DECREASE: 'DECREASE',
  INCREASE: 'INCREASE',
};

export const PROMOTION_TYPE: PromotionTypeStrict = {
  BONUS_DECREASE: 'BONUS_DECREASE',
  BONUS_INCREASE: 'BONUS_INCREASE',
  DISCOUNT: 'DISCOUNT',
  GIFT: 'GIFT',
};

export const PROMOTION_TYPE_STRING: PromotionTypeString = {
  BONUS_DECREASE: 'Списание бонусов',
  BONUS_INCREASE: 'Начисление бонусов',
  DISCOUNT: 'Скидка',
  GIFT: 'Подарок',
};

export const PROMOTION_TYPE_LIST: AutocompleteOption<PromotionType>[] = Object.keys(PROMOTION_TYPE).map((promotionType) => ({
  id: promotionType,
  label: PROMOTION_TYPE_STRING[promotionType],
  type: 'item' as MenuItemType,
  data: promotionType,
}));

export const DISCOUNT_TYPE: DiscountFormulaStrict = {
  ABSOLUTE: 'ABSOLUTE',
  PERCENTAGE: 'PERCENTAGE',
};

export const DISCOUNT_TYPE_STRING: DiscountFormulaString = {
  ABSOLUTE: 'Фиксированная',
  PERCENTAGE: 'Относительная (%)',
};

export const DISCOUNT_TYPE_LIST: AutocompleteOption<DiscountFormula>[] = Object.keys(DISCOUNT_TYPE).map((discountType) => ({
  id: discountType,
  label: DISCOUNT_TYPE_STRING[discountType],
  type: 'item' as MenuItemType,
  data: discountType,
}));

export const BONUS_TYPE: BonusFormulaStrict = {
  RATIO: 'RATIO',
  PERCENTAGE: 'PERCENTAGE',
};

export const BONUS_TYPE_STRING: BonusFormulaString = {
  RATIO: 'В пропорции',
  PERCENTAGE: 'Процент',
};

export const BONUS_TYPE_LIST: AutocompleteOption<BonusFormula>[] = Object.keys(BONUS_TYPE).map((bonusType) => ({
  id: bonusType,
  label: BONUS_TYPE_STRING[bonusType],
  type: 'item' as MenuItemType,
  data: bonusType,
}));

export const getBonusProgramType = (bonusProgramType: string) => Object.keys(BONUS_PROGRAM_TYPE).find((key) => key === bonusProgramType);

export const getBonusProgramTypeByPromotionType = (promotionType: string) => {
  if (promotionType === PROMOTION_TYPE.BONUS_DECREASE) {
    return BONUS_PROGRAM_TYPE.DECREASE;
  }

  if (promotionType === PROMOTION_TYPE.BONUS_INCREASE) {
    return BONUS_PROGRAM_TYPE.INCREASE;
  }

  return BONUS_PROGRAM_TYPE.DECREASE;
};

export const PROGRAM_ACTIVE: ProgramActiveStrict = {
  ACTIVE: 'ACTIVE',
  NOTACTIVE: 'NOTACTIVE',
};

export const PROGRAM_ACTIVE_STRING: ProgramActiveString = {
  ACTIVE: 'Активен',
  NOTACTIVE: 'Неактивен',
};

export const GET_STATUS_PROGRAM = (status: string) => Object.keys(PROGRAM_ACTIVE).find((key) => key === status)!;

export const PROGRAM_ACTIVE_LIST: AutocompleteOption<ProgramActive>[] = Object.keys(PROGRAM_ACTIVE).map((programActive) => ({
  id: programActive,
  label: PROGRAM_ACTIVE_STRING[programActive],
  type: 'item' as MenuItemType,
  data: programActive,
}));

export const isBonusProgram = (type: PromotionType): boolean => type === 'BONUS_DECREASE' || type === 'BONUS_INCREASE';
export const isDiscountProgram = (type: PromotionType): boolean => type === 'DISCOUNT';

export const isGift = (type: PromotionType): boolean => type === 'GIFT';

export const DAYS: WeekDayNew[] = Object.keys(DAY_OF_WEEK).map((dayOfWeek) => ({
  dayOfWeek,
  titleShort: DAY_OF_WEEK_STRING_SHORT[dayOfWeek],
  title: DAY_OF_WEEK_STRING[dayOfWeek],
  periods: [
    {
      minTime: '00:00',
      isMinTimeError: false,
      minTimeError: '',
      maxTime: '23:59',
      isMaxTimeError: false,
      maxTimeError: '',
      isDuplicateTime: false,
      duplicateTimeError: '',
    },
  ],
  isActive: true,
}));

export const PROMOTION_PROGRAM_SORT_FIELD: PromotionProgramSortFieldStrict = {
  NAME: 'NAME',
  ACTIVE: 'ACTIVE',
  PROMOTION_TYPE: 'PROMOTION_TYPE',
  PERIOD: 'PERIOD',
};
