import { Pipe, PipeTransform } from '@angular/core';

import { MenuItemLib } from '@nomia/nomia-components-lib';

import { CustomCurrencyPipe } from '@app/shared/pipe/custom-currency.pipe';
import { NumberSeparatePipe } from '@app/shared/pipe/number-separate.pipe';
import { CoreSchema } from '@typings';

const isMenuItem = (object: CoreSchema.MenuItem | CoreSchema.Product): object is CoreSchema.MenuItem => {
  return 'stockUnit' in object;
};

const isProduct = (object: CoreSchema.MenuItem | CoreSchema.Product): object is CoreSchema.Product => {
  return 'stockUnits' in object;
};

@Pipe({
  name: 'toMenuItemLib',
})
export class ToMenuItemLibPipe implements PipeTransform {
  constructor(private numberSeparatePipe: NumberSeparatePipe, private customCurrencyPipe: CustomCurrencyPipe) {}

  transform(item: CoreSchema.MenuItem | CoreSchema.Product): MenuItemLib {
    let menuItemLib: MenuItemLib | undefined = undefined;

    if (isMenuItem(item)) {
      if (!item.stockUnit) {
        throw new Error(`Unable to convert MenuItem into MenuItemLib when stockUnit is missing`);
      }

      menuItemLib = {
        title: item.name,
        price: `${this.numberSeparatePipe.transform(item.stockUnit.salePrice.amountValue)} ${this.customCurrencyPipe.transform(
          item.stockUnit.salePrice.currencyUnit,
        )}`,
      };

      if (item.stockUnit.images.length) {
        menuItemLib.photo = item.stockUnit.images[0].imageSizes[0].url ?? undefined;
      }
    }

    if (isProduct(item)) {
      menuItemLib = {
        title: item.name,
        price: `${this.numberSeparatePipe.transform(item.stockUnits[0].salePrice.amountValue)} ${this.customCurrencyPipe.transform(
          item.stockUnits[0].salePrice.currencyUnit,
        )}`,
      };

      if (item.stockUnits[0]?.images?.length) {
        menuItemLib.photo = item.stockUnits[0].images[0].imageSizes[0].url ?? undefined;
      }
    }

    if (menuItemLib) {
      return menuItemLib;
    }

    throw new Error(`Unable to convert ${item} into MenuItemLib`);
  }
}
