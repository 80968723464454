import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { FilterTreeNodeData } from '@app/shared/component/filter-tree/filter-tree.component';
import { TreeDataService, TreeNodeFetchResult } from '@app/shared/service/tree-data.service';
import { rootCatalogSection } from '@constants';
import { CatalogSection } from '@typings';
import { getHexCodeByColor } from '@utils';

import { CategoriesStorage } from './categories.storage';

export type InitTreeServiceOptions = {
  excludedSectionIds: string[];
  isRootSection: boolean;
  preloadAllNodes: boolean;
  selectedNodeIds$?: Observable<string[]>;
};

const defaultOptions: InitTreeServiceOptions = {
  excludedSectionIds: [],
  isRootSection: false,
  preloadAllNodes: false,
  selectedNodeIds$: undefined,
};

@Injectable({
  providedIn: 'root',
})
export class InitTreeService {
  constructor(private categoriesStorage: CategoriesStorage) {}

  initSections(options: Partial<InitTreeServiceOptions> = {}): TreeDataService<AutocompleteOption> {
    const mapper = (section: CatalogSection) => {
      const { id, name, isParent, colorSection, workshop, positionType, snoValue, vatValue } = section;

      const option: TreeNodeFetchResult<AutocompleteOption> = {
        id,
        isParent: isParent,
        data: {
          id,
          label: name,
          type: 'item',
          imageColor: colorSection ? getHexCodeByColor(colorSection) : undefined,
          data: {
            name,
            workshop,
            positionType,
            snoValue,
            vatValue,
          },
        },
      };

      return option;
    };

    return this.#initSections(mapper, options);
  }

  initFilterTreeSections(options: Partial<InitTreeServiceOptions> = {}): TreeDataService<FilterTreeNodeData> {
    const mapper = (section: CatalogSection) => {
      const { id, name, isParent } = section;

      const option: TreeNodeFetchResult<FilterTreeNodeData> = {
        id,
        isParent: isParent,
        data: {
          name,
        },
      };

      return option;
    };

    return this.#initSections(mapper, options);
  }

  #initSections<T>(
    mapper: (section: CatalogSection) => TreeNodeFetchResult<T>,
    options: Partial<InitTreeServiceOptions> = {},
  ): TreeDataService<T> {
    const mergedOptions: InitTreeServiceOptions = {
      ...defaultOptions,
      ...options,
    };

    const sectionsTreeService = new TreeDataService<T>();

    let allSections = new ReplaySubject<CatalogSection[]>();

    if (mergedOptions.preloadAllNodes) {
      this.categoriesStorage
        .getSectionsList()
        .pipe(first())
        .subscribe((res) => allSections.next(res.data.sections.content));
    }

    sectionsTreeService.fetchChildren = (parentSectionId, maxDepth): Observable<TreeNodeFetchResult<T>[]> => {
      let fetchedSections$: Observable<CatalogSection[]>;

      if (mergedOptions.preloadAllNodes) {
        fetchedSections$ = allSections.pipe(
          map((allSections) => {
            return allSections.filter((section) => {
              return section.parent?.id === parentSectionId && section.depthLevel <= maxDepth;
            });
          }),
        );
      } else {
        fetchedSections$ = this.categoriesStorage
          .getSectionsList({
            filter: {
              depthLevel: maxDepth,
              parentSectionIds: parentSectionId ? [parentSectionId] : null,
            },
          })
          .pipe(map((res) => res.data.sections.content));
      }

      return fetchedSections$.pipe(
        map((sections) => {
          if (!parentSectionId && mergedOptions.isRootSection) {
            sections = [rootCatalogSection, ...sections];
          }

          return sections
            .filter((section) => {
              const sectionNotInList = !mergedOptions.excludedSectionIds.includes(section.id);

              return parentSectionId ? sectionNotInList : sectionNotInList && section.depthLevel === 0;
            })
            .map(mapper);
        }),
      );
    };

    let loadedPromise;

    if (mergedOptions.preloadAllNodes) {
      loadedPromise = sectionsTreeService.loadAllNodes();
    } else {
      loadedPromise = sectionsTreeService.loadRootNodes();
    }

    loadedPromise.then(() => {
      if (mergedOptions.selectedNodeIds$) {
        mergedOptions.selectedNodeIds$.subscribe((selectedIds) => {
          sectionsTreeService.setSelectedNodeIds(selectedIds);
        });
      }
    });

    return sectionsTreeService;
  }
}
