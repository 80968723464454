<div class="table-sidenav" #tableSidebar>
  <div class="sidenav-top-bar">
    <nm-icon-button
      class="icon"
      variant="text"
      [icon]="'cancelFilter'"
      (click)="onCloseSidebar()"
      data-test-id="sidebar::toggle-button"
      nmTooltip
      [TooltipContent]="'Закрыть'"
    ></nm-icon-button>
    <div class="sidebar-title" *ngIf="!isLoading">{{ title }}</div>
    <nm-icon-button
      *ngIf="(tableSidebarService.items$ | async)?.length"
      variant="text"
      icon="moreHoriz"
      [matMenuTriggerFor]="menu.childMenu"
      data-test-id="topbar::mobile-dots-button"
    ></nm-icon-button>

    <nm-menu #menu class="menu" [selectOnItemClick]="false" [items]="tableSidebarService.items$ | async"></nm-menu>

    <nm-icon-button
      *ngIf="!isLoading && (tableSidebarService.idForRedirect | async)"
      class="icon"
      variant="text"
      icon="expandPage"
      (click)="onExpandPage()"
      data-test-id="sidebar::toggle-button"
      nmTooltip
      [TooltipContent]="'Развернуть на весь экран'"
    ></nm-icon-button>
  </div>

  <div class="content">
    <ng-container *ngIf="isLoading">
      <div class="loading">
        <nm-progress-spinner mode="indeterminate" [diameter]="24" class="organizations-loading"></nm-progress-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div class="content-wrapper" [ngStyle]="{ height: contentHeight }">
        <ng-template #sidenavContainer *ngIf="!isLoading"> </ng-template></div
    ></ng-container>
  </div>
</div>
