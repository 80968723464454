<mat-toolbar [attr.data-test-id]="testId" [ngClass]="componentClasses">
  <nm-icon-button
    testId="topbar::topbar-icon"
    *ngIf="icon"
    variant="text"
    [icon]="icon"
    (click)="onBtnClick()"
  ></nm-icon-button>
  <div class="top-bar-title">
    <span class="title-content">{{ title }}</span>
    <span *ngIf="count" class="title-count">{{ count }}</span>
  </div>
  <ng-content></ng-content>
  <ng-content select="[class=controls]"></ng-content>
</mat-toolbar>
