import { AbcReportV2SortFieldStrict } from '@typings';

export const ABC_REPORT_SORT_FIELD: AbcReportV2SortFieldStrict = {
  NAME: 'NAME',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  PROFIT: 'PROFIT',
  PROFIT_PERCENT: 'PROFIT_PERCENT',
  QUANTITY: 'QUANTITY',
  REVENUE: 'REVENUE',
  UNIT_TYPE: 'UNIT_TYPE',
};
