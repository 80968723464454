<ng-template #placeholder>
  <div class="drag-placeholder"></div>
</ng-template>
<div
  class="table-body"
  (nmDragEnd)="onDropRow($event)"
  (nmDrag)="onDrag($event)"
  [placeholder]="placeholder"
  [dropListDisabled]="!config.dragAndDropOptions?.enable"
  nmDragAndDropList
  #dropList
>
  <nm-table-row
    #row
    *ngFor="let row of rows; let i = index"
    nmScrollVisibility
    [attr.scroll-id]="row.data?.id"
    [threshold]="0.1"
    (hidden)="rowHidden.emit({row, visibilityEvent: $event})"
    (visible)="rowVisible.emit({row, visibilityEvent: $event})"
    (heightInited)="rowHeightInited.emit({row, height: $event})"
    [row]="row"
    [config]="config"
    (selectRow)="onSelectRow($event)"
    (expandRow)="onExpandRow($event)"
    (enumerationUpdate)="onEnumerationUpdate()"
    (dragStart)="onDragStart($event)"
    (dragEnd)="onDragEnd()"
    [columns]="row.columnsData"
    [columnsDataTemplates]="columnsDataTemplates"
    [gridTemplateColumns]="gridTemplateColumns"
    [isDragging]="isRowBeingdraged"
  ></nm-table-row>
</div>
