import { YandexConfigurationStatusStrict, YandexConfigurationStatusString } from '@app/typings/application';

export const CONFIGURATION_STATUS: YandexConfigurationStatusStrict = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  CONFIGURING: 'CONFIGURING',
};

export const CONFIGURATION_STATUS_STRING: YandexConfigurationStatusString = {
  ACTIVE: 'Подключен',
  DISABLED: 'Не подключен',
  CONFIGURING: 'Не подключен',
};
