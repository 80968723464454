import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AccountStorage } from '@core/service/account.storage';
import { SessionStorage } from '@services/api';
import { RedirectService } from '@services/shared';

@Injectable({
  providedIn: 'root',
})
export class SigninGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionStorage: SessionStorage,
    private accountStorage: AccountStorage,
    private redirectService: RedirectService,
  ) {}

  canActivate(): Promise<boolean> | boolean {
    const refreshToken = this.sessionStorage.getRefreshToken();

    if (this.sessionStorage.isAccessTokenValid() && !!this.sessionStorage.getOrgId()) {
      this.redirect(this.sessionStorage.getOrgId());

      return false;
    } else if (refreshToken && !!this.sessionStorage.getOrgId()) {
      this.redirect(this.sessionStorage.getOrgId());
      return false;
    }

    return true;
  }

  redirect(orgId?: string | null) {
    return orgId ? this.redirectService.redirectToAnalytics(orgId) : this.redirectService.redirectToChooseOrganization();
  }
}
