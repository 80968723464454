import { FeatureEnableService } from '@app/shared/service/featureEnable.service';
import {
  ANALYTICS_CHILD_ITEMS,
  CATALOG_CHILD_ITEMS,
  EMPLOYEE_CHILD_ITEMS,
  MARKETING_CHILD_ITEMS,
  SALES_CHILD_ITEMS,
  SETTINGS_CHILD_ITEMS,
  SETTINGS_CHILD_ITEMS_WITH_APPLICATION,
  WAREHOUSE_CHILD_ITEMS,
} from '@constants';
import { AccountStorage } from '@services/core';
import { RedirectService } from '@services/shared';
import { NavDrawerItem } from '@typings';

export const ROUTE_CREATE_NEW = 'new';
export const ROUTE_DATA_LAYOUT = 'withSidebar';
export const ROUTE_IMPORT = 'import';

export enum NavigationRoute {
  stores = 'stores',
  hr = 'hr',
  catalog = 'catalog',
  loyalty = 'loyalty',
  warehouse = 'warehouse',
}

export enum RootNavigationRoute {
  analytics = 'analytics',
  stores = 'stores',
  catalog = 'catalog',
  marketing = 'marketing',
  warehouse = 'warehouse',
  settings = 'settings',
  profile = 'profile',
  bonuses = 'bonuses',
  employees = 'employees',
  sales = 'sales',
  addPositions = 'addPositions',
  addClient = 'addClient',
}

export enum RootNavigationRouteName {
  analytics = 'Аналитика',
  catalog = 'Каталог',
  sales = 'Продажи',
  marketing = 'Маркетинг',
  warehouse = 'Склад',
  settings = 'Настройки',
  profile = 'Профиль',
  bonuses = 'Мои бонусы',
  employees = 'Сотрудники',
  addPositions = 'Добавление позиций',
  addClient = 'Состав группы',
}

export const NAV_DRAWER_ITEMS = (
  feature: FeatureEnableService,
  accountStorage: AccountStorage,
  removeHidden: boolean = false,
): NavDrawerItem[] => {
  let settingsItems: NavDrawerItem[] = feature.applications ? SETTINGS_CHILD_ITEMS_WITH_APPLICATION : SETTINGS_CHILD_ITEMS;

  const settingsItem: NavDrawerItem = {
    title: RootNavigationRouteName.settings,
    route: RootNavigationRoute.settings,
    routes: settingsItems,
    icon: 'settings',
  };

  const profileItem: NavDrawerItem = {
    title: RootNavigationRouteName.profile,
    route: RootNavigationRoute.profile,
    hideInDrawer: true,
  };
  const addPositionItem: NavDrawerItem = {
    title: RootNavigationRouteName.addPositions,
    route: RootNavigationRoute.addPositions,
    icon: 'cancelFilter',
    hideInDrawer: true,
  };

  const addClientItem: NavDrawerItem = {
    title: RootNavigationRouteName.addClient,
    route: RootNavigationRoute.addClient,
    icon: 'cancelFilter',
    hideInDrawer: true,
  };

  if (accountStorage.getIsCacher()) {
    const bonusesItem: NavDrawerItem = {
      title: RootNavigationRouteName.bonuses,
      route: RootNavigationRoute.bonuses,
      icon: 'bonuses',
      onClick: function (orgId: string, redirectService: RedirectService) {
        redirectService.redirectToCashierPage(orgId);
      },
    };

    return [bonusesItem, profileItem];
  }

  let items: NavDrawerItem[] = [
    {
      title: RootNavigationRouteName.analytics,
      route: RootNavigationRoute.analytics,
      routes: ANALYTICS_CHILD_ITEMS,
      icon: 'analytic',
    },
    {
      title: RootNavigationRouteName.sales,
      route: RootNavigationRoute.sales,
      routes: SALES_CHILD_ITEMS,
      icon: 'store',
    },
    {
      title: RootNavigationRouteName.catalog,
      route: RootNavigationRoute.catalog,
      routes: CATALOG_CHILD_ITEMS,
      icon: 'catalog',
    },
    {
      title: RootNavigationRouteName.marketing,
      route: RootNavigationRoute.marketing,
      routes: MARKETING_CHILD_ITEMS,
      icon: 'loyalty',
    },
    {
      title: RootNavigationRouteName.warehouse,
      route: RootNavigationRoute.warehouse,
      routes: WAREHOUSE_CHILD_ITEMS,
      icon: 'storage',
    },
    {
      title: RootNavigationRouteName.employees,
      route: RootNavigationRoute.employees,
      routes: EMPLOYEE_CHILD_ITEMS,
      icon: 'staff',
    },
    settingsItem,
    profileItem,
    addPositionItem,
    addClientItem,
  ];

  if (removeHidden) {
    items = removeHiddenItems(items);
  }

  addParentLinks(items);

  return items;
};

export function addParentLinks(items: NavDrawerItem[], parentLink?: NavDrawerItem): void {
  items.forEach((item) => {
    item.parent = parentLink;

    if (item.routes) {
      addParentLinks(item.routes, item);
    }
  });
}

export function removeHiddenItems(items: NavDrawerItem[]): NavDrawerItem[] {
  return items
    .filter((item) => !item.hideInDrawer)
    .map((item) => ({
      ...item,
      routes: item.routes ? removeHiddenItems(item.routes) : undefined,
    }));
}
