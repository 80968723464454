import { gql } from 'apollo-angular';

import { fragmentRequestResult } from './mutations';

export const queryMenu = gql`
  query menu($id: UUID!) {
    menu(id: $id) {
      id
      name
      storeId
      type
      countOfSections {
        inactive
        total
      }
      countOfSubSections {
        inactive
        total
      }
      countOfItems {
        inactive
        total
      }
      menuSections {
        id
        name
        depthLevel
        colorSection
        isParent
        image {
          id
          imageSizes {
            url
          }
        }
        parentSectionId
        active
        orderPosition
        countOfItems {
          inactive
          total
        }
        countOfSections {
          inactive
          total
        }
        onlineMenuSection {
          menuSectionId
          imageId
          imageUrl
        }
      }
      onlineMenu {
        id
        active
        shortId
        sections {
          menuSectionId
          imageId
          imageUrl
        }
      }
    }
  }
`;
export const queryMenuRc = gql`
  query menuRc($id: UUID!) {
    menuRc(id: $id) {
      id
      itemsCount {
        total
        inactive
      }
      name
      storeId
      type
    }
  }
`;
export const queryAllInMenu = gql`
  query findMenuElements($filter: MenuElementFilterInput, $pageable: PageRequestInput) {
    findMenuElements(filter: $filter, pageable: $pageable) {
      content {
        ... on MenuItem {
          id
          name
          active
          menuSectionId
          orderPosition
          itemSalePrice {
            amountValue
            currencyUnit
          }
          stockUnit {
            id
            name
            unit
            primePrice {
              amountValue
              currencyUnit
            }
            images {
              id
              imageSizes {
                url
              }
            }
          }
          onlineMenuItem {
            menuItemId
            imageId
            imageUrl
          }
        }
        ... on MenuSection {
          id
          name
          depthLevel
          colorSection
          isParent
          image {
            id
            imageSizes {
              url
            }
          }
          parentSectionId
          active
          orderPosition
          countOfItems {
            inactive
            total
          }
        }
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryAllInMenuPageable = gql`
  query menuRcElements($filter: MenuRcElementFilterInput, $pageable: PageRequestInput!) {
    menuRcElements(filter: $filter, pageable: $pageable) {
      elements {
        ... on ItemRc {
          active
          id
          menuId
          name
          salePrice
          sectionId
          stockUnitId
          leftMargin
          rightMargin
        }
        ... on SectionRc {
          active
          color
          depthLevel
          id
          image {
            id
            imageSizes {
              url
            }
          }
          leftMargin
          menuId
          name
          parentId
          rightMargin
        }
      }
      page {
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryMenuElementsShort = gql`
  query menuRcElements($filter: MenuRcElementFilterInput, $pageable: PageRequestInput!) {
    menuRcElements(filter: $filter, pageable: $pageable) {
      elements {
        ... on ItemRc {
          id
          name
          stockUnitId
          leftMargin
          rightMargin
        }
        ... on SectionRc {
          color
          id
          leftMargin
          name
          rightMargin
        }
      }
      page {
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryMenuElementShort = gql`
  query menuRcElement($elementId: UUID!) {
    menuRcElement(elementId: $elementId) {
      element {
        ... on ItemRc {
          sectionId
          rightMargin
          leftMargin
        }
        ... on SectionRc {
          id
          parentId
          rightMargin
          leftMargin
        }
      }
    }
  }
`;

export const queryMenuElement = gql`
  query menuRcElement($elementId: UUID!) {
    menuRcElement(elementId: $elementId) {
      element {
        ... on ItemRc {
          active
          id
          menuId
          name
          salePrice
          sectionId
          stockUnitId
          leftMargin
          rightMargin
        }
        ... on SectionRc {
          active
          color
          depthLevel
          id
          image {
            id
            imageSizes {
              url
            }
          }
          leftMargin
          menuId
          name
          parentId
          rightMargin
        }
      }
    }
  }
`;
export const queryMenuRcRootElements = gql`
  query menuRcRootElements($menuId: UUID!, $pageable: PageRequestInput!) {
    menuRcRootElements(menuId: $menuId, pageable: $pageable) {
      elements {
        ... on ItemRc {
          active
          id
          menuId
          name
          salePrice
          sectionId
          stockUnitId
          leftMargin
          rightMargin
        }
        ... on SectionRc {
          active
          color
          depthLevel
          id
          image {
            id
            imageSizes {
              url
            }
          }
          leftMargin
          menuId
          name
          parentId
          rightMargin
        }
      }
      page {
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryMenuRcImmediateDescendants = gql`
  query menuRcImmediateDescendants($menuId: UUID!, $parentId: UUID!, $pageable: PageRequestInput!) {
    menuRcImmediateDescendants(menuId: $menuId, parentId: $parentId, pageable: $pageable) {
      elements {
        ... on ItemRc {
          active
          id
          menuId
          name
          salePrice
          sectionId
          stockUnitId
          leftMargin
          rightMargin
        }
        ... on SectionRc {
          active
          color
          depthLevel
          id
          image {
            id
            imageSizes {
              url
            }
          }
          leftMargin
          menuId
          name
          parentId
          rightMargin
        }
      }
      page {
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryChildrenShort = gql`
  query menuRcImmediateDescendants($menuId: UUID!, $parentId: UUID!, $pageable: PageRequestInput!) {
    menuRcImmediateDescendants(menuId: $menuId, parentId: $parentId, pageable: $pageable) {
      elements {
        ... on ItemRc {
          active
          id
          name
          stockUnitId
          leftMargin
          rightMargin
        }
        ... on SectionRc {
          active
          color
          id
          leftMargin
          name
          rightMargin
        }
      }
    }
  }
`;

export const mutationMoveElements = gql`
  mutation moveElements($input: MoveElementsInput!) {
    moveElements(input: $input) {
      status {
        result
      }
    }
  }
`;

export const mutationCreateMenu = gql`
  mutation createMenu($menuCreateInput: MenuCreateInput!) {
    createMenu(menuCreateInput: $menuCreateInput)
  }
`;

export const mutationCreateMenuItem = gql`
  mutation createMenuItem($menuItem: MenuItemInput!) {
    createMenuItem(menuItem: $menuItem)
  }
`;
export const mutationCreateMenuWithStructure = gql`
  mutation createMenuStructure($menu: MenuCreateInput!, $struct: MenuStructureInput!) {
    createMenuStructure(menu: $menu, struct: $struct) {
      result
      errorMessage
      transactionId
    }
  }
`;
export const mutationCreateMenuRc = gql`
  mutation createMenuRc($menu: MenuRcCreateInput!, $struct: MenuRcStructureInput!) {
    createMenuRc(menu: $menu, struct: $struct) {
      menu {
        id
      }
    }
  }
`;

export const mutationAddMenuStructure = gql`
  mutation addMenuStructure($menuId: UUID!, $struct: MenuStructureInput!, $categoryId: UUID) {
    addMenuStructure(menuId: $menuId, struct: $struct, categoryId: $categoryId) {
      result
      errorMessage
      transactionId
    }
  }
`;
export const mutationAddMenuRc = gql`
  mutation addMenuRc($menuId: UUID!, $struct: MenuRcStructureInput!, $categoryId: UUID) {
    addMenuRc(menuId: $menuId, struct: $struct, categoryId: $categoryId) {
      status {
        result
        errorMessage
      }
    }
  }
`;

export const mutationCreateMenuItemsList = gql`
  mutation createMenuItemsList($menuItems: [MenuItemInput!]!) {
    createMenuItemsList(menuItems: $menuItems) {
      entityId
      errorMessage
      result
    }
  }
`;

export const mutationDeleteMenu = gql`
  mutation deleteMenu($id: UUID!) {
    deleteMenu(id: $id) {
      result
      errorMessage
      entityId
    }
  }
`;

export const mutationDeleteMenuRc = gql`
  mutation deleteMenuRc($id: UUID!) {
    deleteMenuRc(id: $id) {
      status {
        result
        errorMessage
      }
    }
  }
`;
export const mutationUpdateMenuRc = gql`
  mutation updateMenuRc($input: UpdateMenuInput!) {
    updateMenuRc(input: $input) {
      menu {
        id
        storeId
        itemsCount {
          inactive
          total
        }
        type
        name
      }
      status {
        result
      }
    }
  }
`;

export const mutationCreateMenuSection = gql`
  mutation createMenuSectionV2($menuSectionCreateInput: MenuSectionCreateInput!) {
    createMenuSectionV2(menuSectionCreateInput: $menuSectionCreateInput) {
      ...RequestResult
    }
  }
  ${fragmentRequestResult}
`;

export const mutationCreateMenuSectionRc = gql`
  mutation createSectionRc($input: SectionCreateInput!) {
    createSectionRc(input: $input) {
      section {
        id
      }
      status {
        result
      }
    }
  }
`;

export const mutationUpdateMenuSectionRc = gql`
  mutation updateSectionRc($input: SectionUpdateInput!) {
    updateSectionRc(input: $input) {
      status {
        result
      }
      section {
        active
        color
        id
        image {
          id
          imageSizes {
            url
          }
        }
        leftMargin
        menuId
        name
        rightMargin
        parentId
      }
    }
  }
`;

export const mutationDeleteMenuSectionRc = gql`
  mutation deleteSectionRc($id: UUID!) {
    deleteSectionRc(id: $id) {
      status {
        result
      }
    }
  }
`;

export const mutationChangeSectionRcPosition = gql`
  mutation changeSectionRcPosition($input: ChangeSectionRcPositionInput!) {
    changeSectionRcPosition(input: $input) {
      status {
        response
      }
    }
  }
`;

export const mutationCreateItemsRcList = gql`
  mutation createItemsRcList($items: [CreateItemInput!]!) {
    createItemsRcList(items: $items) {
      status {
        result
      }
    }
  }
`;

export const mutationUpdateMenuItemRc = gql`
  mutation updateItemRc($item: UpdateItemInput!) {
    updateItemRc(item: $item) {
      status {
        result
      }
      item {
        active
        id
        menuId
        name
        salePrice
        stockUnitId
      }
    }
  }
`;

export const mutationDeleteMenuItemRc = gql`
  mutation deleteItemRc($id: UUID!) {
    deleteItemRc(id: $id) {
      status {
        result
      }
    }
  }
`;

export const mutationChangeMenuItemRcPosition = gql`
  mutation changeItemRcPosition($input: UpdateItemPositionInput!) {
    changeItemRcPosition(input: $input) {
      status {
        result
      }
    }
  }
`;
export const mutationDuplicateMenuRc = gql`
  mutation duplicateMenuRc($input: MenuRcDuplicateInput) {
    duplicateMenuRc(input: $input) {
      storesMenus {
        menuId
        storeId
      }
    }
  }
`;

export const mutationDeleteMenuItem = gql`
  mutation deleteMenuItem($id: UUID!) {
    deleteMenuItem(id: $id)
  }
`;

export const mutationDeleteMenuSection = gql`
  mutation deleteMenuSection($id: UUID!) {
    deleteMenuSection(id: $id) {
      entityId
      errorMessage
      result
    }
  }
`;

export const mutationDuplicateMenu = gql`
  mutation duplicateMenu($menuDuplicateInput: MenuDuplicateInput) {
    duplicateMenu(menuDuplicateInput: $menuDuplicateInput) {
      storesMenus {
        menuId
        storeId
      }
    }
  }
`;

export const mutationUpdateMenu = gql`
  mutation updateMenu($menuUpdateInput: MenuUpdateInput!) {
    updateMenu(menuUpdateInput: $menuUpdateInput)
  }
`;

export const mutationUpdateMenuItem = gql`
  mutation updateMenuItem($menuItem: MenuItemInput!) {
    updateMenuItem(menuItem: $menuItem)
  }
`;

export const mutationUpdateMenuItemsList = gql`
  mutation updateMenuItemsList($menuItems: [MenuItemInput!]!) {
    updateMenuItemsList(menuItems: $menuItems) {
      entityId
      result
      errorMessage
    }
  }
`;

export const mutationUpdateMenuSection = gql`
  mutation updateMenuSectionV2($menuSectionUpdateInput: MenuSectionUpdateInput!) {
    updateMenuSectionV2(menuSectionUpdateInput: $menuSectionUpdateInput) {
      ...RequestResult
    }
  }
  ${fragmentRequestResult}
`;

export const updateMenuSectionV2Patch = gql`
  mutation updateMenuSectionV2Patch($menuSectionUpdateInput: MenuSectionPatchUpdateInput!) {
    updateMenuSectionV2Patch(menuSectionUpdateInput: $menuSectionUpdateInput) {
      output {
        id
        name
        depthLevel
        colorSection
        isParent
        image {
          id
          imageSizes {
            url
          }
        }
        parentSectionId
        active
        orderPosition
        countOfItems {
          inactive
          total
        }
      }
      result
      errorMessage
    }
  }
`;

export const changeMenuSectionPosition = gql`
  mutation changeMenuSectionPosition($positionUpdate: MenuSectionPositionUpdateInput!) {
    changeMenuSectionPosition(positionUpdate: $positionUpdate) {
      output {
        id
        orderPosition
      }
      result
      errorMessage
    }
  }
`;

export const mutationUpdateMenuSectionsList = gql`
  mutation updateMenuSectionsList($menuSectionsUpdateInputList: [MenuSectionUpdateInput]!) {
    updateMenuSectionsList(menuSectionsUpdateInputList: $menuSectionsUpdateInputList) {
      entityId
      errorMessage
      result
    }
  }
`;

export const getPrimePriceAndImageForMenuItem = gql`
  query stockUnits($filter: StockUnitFilterInput, $pageRequest: PageRequestInput) {
    stockUnits(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        primePrice {
          amountValue
          currencyUnit
        }
        weightedAveragePrimePrice {
          amountValue
          currencyUnit
        }
        images {
          id
          originalFileName
          extension
          imageSizes {
            url
          }
        }
      }
    }
  }
`;
