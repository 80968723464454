import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_VIEW = 'DD.MM.YYYY';
export const DATE_FULL_FORMAT_VIEW = 'DD MMM YYYY';
export const DATE_FULL_WITH_TIME_FORMAT_VIEW = 'DD.MM.YYYY, HH:mm';
export const DATE_TIME_FULL_FORMAT_VIEW = 'DD MMM YYYY HH:mm';
export const MONTH_DATE_FORMAT = 'D MMM';
export const MONTH_DAY_FORMAT = 'DD MMM';
export const MONTH_YEAR_FORMAT = 'MMM YY';
export const FULL_MONTH_YEAR_FORMAT = 'MMMM YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const HOUR_MINUTE_FORMAT = 'HH:mm';
export const DATE_TEMPLATE = 'dd.MM.yyyy';
export const DATE_FULL_TEMPLATE = 'dd.MM.yyyy HH:mm';

export const DATE_ISO = 'YYYY-MM-DDT00:00:00.000[Z]';
export const DATE_PRESETS: DATE_PRESETS_TYPE = {
  CUSTOM: 'Произвольный период',
  TODAY: 'Сегодня',
  CURRENT_WEEK: 'Текущая неделя',
  CURRENT_MONTH: 'Текущий месяц',
  YESTERDAY: 'Вчера',
  LAST_WEEK: 'Прошлая неделя',
  LAST_MONTH: 'Прошлый месяц',
  LAST_SEVEN_DAYS: 'Последние 7 дней',
  LAST_THIRTY_DAYS: 'Последние 30 дней',
  LAST_ONE_YEAR: 'Последние 12 месяцев',
};

export type DATE_PRESETS_TYPE = {
  CUSTOM: string;
  TODAY: string;
  CURRENT_WEEK: string;
  CURRENT_MONTH: string;
  YESTERDAY: string;
  LAST_WEEK: string;
  LAST_MONTH: string;
  LAST_SEVEN_DAYS: string;
  LAST_THIRTY_DAYS: string;
  LAST_ONE_YEAR: string;
};

export const DATE_PRESETS_LIST: AutocompleteOption<keyof DATE_PRESETS_TYPE>[] = Object.keys(DATE_PRESETS).map((key) => {
  return {
    id: key,
    label: DATE_PRESETS[key],
    type: 'item',
    data: key,
  };
});

export const DATE_FILTERS: DATE_FILTERS_TYPE = {
  TODAY: 'Сегодня',
  CURRENT_WEEK: 'Текущая неделя',
  CURRENT_MONTH: 'Текущий месяц',
  YESTERDAY: 'Вчера',
  LAST_WEEK: 'Прошлая неделя',
  LAST_MONTH: 'Прошлый месяц',
  CUSTOM: 'Произвольный период',
};

export const DATE_FILTERS_COMPARE: DATE_FILTERS_TYPE = {
  TODAY: 'со вчера',
  CURRENT_WEEK: 'с этими днями на прошлой неделе',
  CURRENT_MONTH: 'с этими днями в прошлом месяце',
  YESTERDAY: 'с позавчера',
  LAST_WEEK: 'с позапрошлой неделей',
  LAST_MONTH: 'с позапрошлым месяцем',
  CUSTOM: '',
};

export type DATE_FILTERS_TYPE = {
  CUSTOM: string;
  TODAY: string;
  CURRENT_WEEK: string;
  CURRENT_MONTH: string;
  YESTERDAY: string;
  LAST_WEEK: string;
  LAST_MONTH: string;
};

export const DATE_FILTER_LIST: MenuItem[] = Object.keys(DATE_FILTERS).map((key) => {
  return {
    id: key,
    label: DATE_FILTERS[key],
    type: 'item',
    description: DATE_FILTERS_COMPARE[key],
    showItemDescription: key === 'CUSTOM',
  };
});
