import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { BehaviorSubject } from 'rxjs';

import {
  ACCESS_TOKEN_KEY,
  CURRENCY_UNIT_KEY,
  EMPTY_AUTH_HEADER_TOKEN,
  HIDE_SUBSCRITPTION_NOTIFICATION,
  ORG_ID_KEY,
  REFRESH_TOKEN_DELAY,
  REFRESH_TOKEN_KEY,
  SIDEBAR_VIEW_KEY,
  SUPER_USER_KEY,
  TIME_SHIFT_KEY,
} from '@constants';
import { AuthData } from '@constants/auth';
import { environment } from '@env/environment';
import { getFingerprint, SessionApiService } from '@services/api';
import { RedirectService } from '@services/shared';
import { CURRENCY, SessionJWT, SidebarViewType } from '@typings';
import { getDateWithoutOffset, jwtDecode } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class SessionStorage {
  #fingerprint: string = '';

  orgId$ = new BehaviorSubject<string | null>(null);
  currencyUnit$ = new BehaviorSubject<string | null>(null);
  shift$ = new BehaviorSubject<string | null>(null);
  sidebarView$ = new BehaviorSubject<SidebarViewType>(this.getSidebarView());

  // To save state for orders-reports filters
  orderStatuses$ = new BehaviorSubject<string[] | string>('');
  orderPayments$ = new BehaviorSubject<string[] | string>('');
  orderShifts$ = new BehaviorSubject<string[] | string>('');
  orderDatepicker$ = new BehaviorSubject<DateRange<Date> | null>(null);

  constructor(private redirectService: RedirectService, private api: SessionApiService) {
    this.#fingerprint = getFingerprint();
  }

  getHideSubscriptionBanner(): boolean {
    return !!localStorage.getItem(HIDE_SUBSCRITPTION_NOTIFICATION);
  }

  setHideSubscriptionBanner(hide: boolean): void {
    if (hide) {
      localStorage.setItem(HIDE_SUBSCRITPTION_NOTIFICATION, 'true');
    } else {
      localStorage.removeItem(HIDE_SUBSCRITPTION_NOTIFICATION);
    }
  }

  getOrgId(): string | null {
    return this.orgId$?.getValue() || localStorage.getItem(ORG_ID_KEY) || null;
  }

  setOrgId(id: string): void {
    localStorage.setItem(ORG_ID_KEY, id);

    if (id) {
      this.orgId$.next(id);
    }
  }

  removeOrgId() {
    localStorage.removeItem(ORG_ID_KEY);
  }

  getCurrencyUnit(): string {
    return this.currencyUnit$.getValue() || localStorage.getItem(CURRENCY_UNIT_KEY) || CURRENCY.RUB;
  }

  setCurrencyUnit(unit: string) {
    localStorage.setItem(CURRENCY_UNIT_KEY, unit);

    if (unit) {
      this.currencyUnit$.next(unit);
    }
  }

  removeCurrencyUnit() {
    localStorage.removeItem(CURRENCY_UNIT_KEY);
  }

  setFilters() {
    localStorage.setItem('analyticStores', '');
    localStorage.setItem('analyticPeriod', '');
    localStorage.setItem('analyticPeriodPreset', 'CURRENT_WEEK');
  }

  getFilters(key: string): string[] {
    return (localStorage.getItem(key) || '').split(',');
  }

  getPeriodFilters(): DateRange<Date> | null {
    const period = localStorage.getItem('analyticPeriod') ? localStorage.getItem('analyticPeriod')!.split('|') : null;
    let datepickerRange: DateRange<Date> | null = null;

    if (period) {
      const start = period[0];
      const end = period[1];

      datepickerRange = new DateRange(getDateWithoutOffset(start), getDateWithoutOffset(end));
    }

    return datepickerRange;
  }

  getPeriodPreset() {
    return localStorage.getItem('analyticPeriodPreset') || 'CURRENT_WEEK';
  }

  removeFilters() {
    localStorage.removeItem('analyticStores');
    localStorage.removeItem('analyticPeriod');
    localStorage.removeItem('analyticPeriodPreset');
  }

  checkCredentials() {
    return !!localStorage.getItem('access');
  }

  getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN_KEY) || null;
  }

  setAccessToken(token: string): void {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  }

  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  getTimeShift(): string | null {
    return this.shift$.getValue() || localStorage.getItem(TIME_SHIFT_KEY) || null;
  }

  setTimeShift(token: string): void {
    const { iat } = this.decodeToken(token);
    const iatTime = iat * 1000;
    const shift = String(Date.now() - iatTime);
    if (shift) {
      this.shift$.next(shift);
    }

    localStorage.setItem(TIME_SHIFT_KEY, shift);
  }

  setSuperUser(token: string): void {
    const { superuser } = this.decodeToken(token);
    localStorage.setItem(SUPER_USER_KEY, String(superuser));
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN_KEY) || null;
  }

  setRefreshToken(token: string): void {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
  }

  saveToken(token: AuthData) {
    if (this.isTokenCorrect(token.access_token)) {
      this.setAccessToken(token.access_token);
      this.setTimeShift(token.access_token);
      this.setSuperUser(token.access_token);
    }
    this.setRefreshToken(token.refresh_token);
  }

  decodeToken(token: string | null): SessionJWT {
    const defaultToken: SessionJWT = { exp: 0, iat: 0, accountId: '', hasPassword: false, superuser: false };

    if (!token) {
      return defaultToken;
    }

    try {
      return jwtDecode<SessionJWT>(token);
    } catch {
      return defaultToken;
    }
  }

  isTokenCorrect(token: string): boolean {
    const { exp } = this.decodeToken(token);

    return exp > 0;
  }

  getDecodedAccessToken(): SessionJWT {
    return this.decodeToken(this.getAccessToken());
  }

  isTokenValid(token: string): boolean {
    const { exp } = this.decodeToken(token);
    const getTimeShift = this.getTimeShift();

    return Date.now() < exp * 1000 - REFRESH_TOKEN_DELAY + Number(getTimeShift);
  }

  isAccessTokenValid(): boolean {
    const token = this.getAccessToken();

    if (!token) {
      return false;
    }

    return this.isTokenValid(token);
  }

  clear(): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(ORG_ID_KEY);
    localStorage.removeItem(TIME_SHIFT_KEY);
    localStorage.removeItem(HIDE_SUBSCRITPTION_NOTIFICATION);
    this.orgId$.next(null);
    this.currencyUnit$.next(null);
    this.shift$.next(null);
  }

  fetchRefreshToken(): Promise<Response> {
    const refreshToken = this.getRefreshToken();

    if (!refreshToken) {
      throw new Error(`Refresh token is missing: ${refreshToken}`);
    }

    return this.api
      .fetchRefreshToken<AuthData>(refreshToken)
      .then((data: AuthData) => {
        if (data) {
          this.saveToken(data);
        }
        return data;
      })
      .catch((err) => {
        this.clear();
        this.login();
        return err;
      });
  }

  async updateTokens() {
    return await this.fetchRefreshToken();
  }

  login() {
    const redirect = `${window.location.origin}/auth/login`;
    window.location.href = `${environment.authUrl}/oauth2/authorize?response_type=code&client_id=erp&redirect_uri=${redirect}&scope=owner`;
  }

  getAuthHeader(): string {
    const token = this.getAccessToken();

    return token ? `Bearer ${token}` : EMPTY_AUTH_HEADER_TOKEN;
  }

  getFingerprintHeader(): string {
    return this.#fingerprint;
  }

  async redirectLogin(message?: string): Promise<Boolean> {
    if (message) {
      console.warn(message);
    }

    return this.redirectService.redirectToLogin();
  }

  getSidebarView(): SidebarViewType {
    return (localStorage.getItem(SIDEBAR_VIEW_KEY) as SidebarViewType) || 'default';
  }

  isCompactSidebarView(): boolean {
    return this.getSidebarView() === 'compact';
  }

  toggleSidebarView(): void {
    this.setSidebarView(this.isCompactSidebarView() ? 'default' : 'compact');
  }

  setSidebarView(view: SidebarViewType): void {
    localStorage.setItem(SIDEBAR_VIEW_KEY, view);

    if (view) {
      this.sidebarView$.next(view);
    }
  }

  setSelectedItems(items: Array<string>) {
    localStorage.setItem('selectedItems', items.join(','));
  }

  getSelectedItems(): Array<string> {
    return localStorage
      .getItem('selectedItems')
      ?.split(',')
      .filter((item) => item)!;
  }

  redirectPaymentRequired(orgId: string, message?: string): Promise<Boolean> {
    if (message) {
      console.warn(message);
    }

    return this.redirectService.redirectToPaymentRequired(orgId);
  }

  getOrderStatuses() {
    return this.orderStatuses$.getValue();
  }

  getOrderShifts() {
    return this.orderShifts$.getValue();
  }

  getOrderPayments() {
    return this.orderPayments$.getValue();
  }

  getOrderDatepicker() {
    return this.orderDatepicker$.getValue();
  }
}
