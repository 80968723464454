export enum AnalyticsRoute {
  abcReport = 'abc-report',
  productsReport = 'products-report',
  categoriesReport = 'categories-report',
  dashboardReport = 'dashboard-report',
}

export enum AnalyticsRouteName {
  abcReport = 'ABC-отчет',
  productsReport = 'Отчет по товарам',
  categoriesReport = 'Отчет по категориям',
  dashboardReport = 'Обзор организации',
  shifts = 'Кассовые смены',
}

export enum AnalyticsRouteParam {
  productId = 'idProduct',
}

export const ANALYTICS_ITEM_ABC = {
  title: AnalyticsRouteName.abcReport,
  route: AnalyticsRoute.abcReport,
};

export const ANALYTICS_ITEM_PRODUCTS = {
  title: AnalyticsRouteName.productsReport,
  route: AnalyticsRoute.productsReport,
  routes: [
    {
      title: '',
      route: AnalyticsRouteParam.productId,
      keepUrlParams: true,
    },
  ],
};

export const ANALYTICS_ITEM_CATEGORIES = {
  title: AnalyticsRouteName.categoriesReport,
  route: AnalyticsRoute.categoriesReport,
};

export const ANALYTICS_ITEM_DASHBOARD = {
  title: AnalyticsRouteName.dashboardReport,
  route: AnalyticsRoute.dashboardReport,
};

export const ANALYTICS_CHILD_ITEMS = [ANALYTICS_ITEM_DASHBOARD, ANALYTICS_ITEM_ABC, ANALYTICS_ITEM_PRODUCTS, ANALYTICS_ITEM_CATEGORIES];
