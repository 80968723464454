<nm-modal-base
  (cancel)="closeForm(false)"
  (ok)="closeForm(true)"
  [okTitle]="btnTitle"
  okVariant="filled"
  [cancelTitle]="'Отменить'"
  testId="menu::menu-items-modal"
>
  <div modal-base-title>{{ title }}</div>
  <ng-container *ngIf="menuService.menuItemsForm as form">
    <nm-form class="form" [form]="form">
      <nm-autocomplete
        ngDefaultControl
        label="Родительская категория"
        placeholder="Выберите родительскую категорию"
        [search]="true"
        [formControl]="subsectionControl"
        [error]="validationErrorsService.isControlDirtyAndInvalid(subsectionControl)"
        [hint]="
          validationErrorsService.validateField(subsectionControl) || subsectionControl.getError('subsectionControl')
        "
        [searchFn]="menuSectionsAutocompleteTreeService.searchFn"
        [getSelectedOptionFn]="menuSectionsAutocompleteTreeService.getSelectedOptionFn"
        [options]="menuSectionsAutocompleteTreeService.options$"
        [autocompleteTreeService]="menuSectionsAutocompleteTreeService"
        [createOption]="true"
        (create)="createSection($event)"
      ></nm-autocomplete>

      <nm-autocomplete
        #autocompleteCatalog
        label="Позиции"
        testId="menu-create::categories-and-products"
        placeholder="Выберите категории"
        ngDefaultControl
        [isMulti]="true"
        [returnObjects]="true"
        [search]="true"
        [formControl]="structure"
        [required]="true"
        [error]="validationErrorsService.isControlDirtyAndInvalid(structure)"
        [hint]="validationErrorsService.validateField(structure) || structure.getError('structure')"
        [searchFn]="autocompleteTreeService.searchFn"
        [getSelectedOptionFn]="autocompleteTreeService.getSelectedOptionFn"
        [options]="autocompleteTreeService.options$"
        [multiSelectTreeUpdateSelected]="autocompleteTreeService.updateSelected | async"
        [allSelected]="menuService.allCatalogSelected$ | async"
        [selectAllSettings]="{
          title: 'Все категории и позиции'
        }"
        [autocompleteTreeService]="autocompleteTreeService"
        collapseChipsLabel="PRODUCTS_AND_CATEGORIES"
        (selectAllHandler)="menuService.setAllCatalogSelected($event.selected)"
      ></nm-autocomplete>
      <div class="checkbox" style="display: flex; align-items: center; gap: 7px">
        <nm-checkbox (checkboxChange)="change($event)"></nm-checkbox> Импортировать товары из каталога вместе со
        структурой категорий
      </div>
    </nm-form>
  </ng-container>
</nm-modal-base>
