import { ActionsComponent } from '@app/shared/component/actions/actions.component';
import { ROUTE_CREATE_NEW, ROUTE_IMPORT } from '@constants';
import { NavDrawerItem, ProductType } from '@typings';

export enum CatalogRoute {
  categories = 'categories',
  products = 'products',
  ingredients = 'ingredients',
  semimanufactures = 'semimanufactures',
  modifiersGroups = 'modifiersGroups',
  modifiers = 'modifiers',
  menus = 'menus',
  settings = 'settings',
  workshops = 'workshops',
}

export enum CatalogRouteName {
  categories = 'Категории и цеха',
  products = 'Товары и техкарты',
  ingredients = 'Ингредиенты',
  semimanufactures = 'Полуфабрикаты',
  modifiersGroups = 'Группы модификаторов',
  modifiers = 'Модификаторы',
  menus = 'Меню',
  settings = 'Настройка каталога',
  workshops = 'Цеха',
}

export enum CatalogRouteParam {
  categoryId = 'idCategory',
  productId = 'idProduct',
  ingredientId = 'idIngredient',
  semimanufactureId = 'idSemimanufacture',
  modifierGroupId = 'idModifierGroup',
  modifierId = 'idModifier',
  menuId = 'idMenu',
}

export const CATALOG_ITEM_CATEGORIES = {
  title: CatalogRouteName.categories,
  route: CatalogRoute.categories,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новая категория',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Категория',
      route: CatalogRouteParam.categoryId,
    },
  ],
};

export const CATALOG_ITEM_PRODUCTS = {
  title: CatalogRouteName.products,
  route: CatalogRoute.products,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новый товар',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Товар',
      route: CatalogRouteParam.productId,
    },
    {
      title: 'Импорт',
      route: ROUTE_IMPORT,
    },
  ],
};

export const CATALOG_ITEM_INGREDIENTS = {
  title: CatalogRouteName.ingredients,
  route: CatalogRoute.ingredients,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новый ингредиент',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Ингредиент',
      route: CatalogRouteParam.ingredientId,
    },
  ],
};

export const CATALOG_ITEM_SEMIMANUFACTURES = {
  title: CatalogRouteName.semimanufactures,
  route: CatalogRoute.semimanufactures,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новый полуфабрикат',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Полуфабрикат',
      route: CatalogRouteParam.semimanufactureId,
    },
  ],
};

export const CATALOG_ITEM_MODIFIER_GROUPS = {
  title: CatalogRouteName.modifiersGroups,
  route: CatalogRoute.modifiersGroups,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новая группа модификаторов',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Группа модификаторов',
      route: CatalogRouteParam.modifierGroupId,
    },
  ],
};

export const CATALOG_ITEM_MODIFIERS = {
  title: CatalogRouteName.modifiers,
  route: CatalogRoute.modifiers,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новый модификатор',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Модификатор',
      route: CatalogRouteParam.modifierId,
    },
  ],
};

export const CATALOG_ITEM_MENU = {
  title: CatalogRouteName.menus,
  route: CatalogRoute.menus,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новое меню',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Меню',
      route: CatalogRouteParam.menuId,
      actionsComponent: ActionsComponent,
      routes: [
        {
          title: 'Импорт',
          route: ROUTE_IMPORT,
        },
      ],
    },
  ],
};

export const CATALOG_ITEM_SETTINGS = {
  title: CatalogRouteName.settings,
  route: CatalogRoute.settings,
  hideInDrawer: true,
  isStacking: true,
  substituteItem: CATALOG_ITEM_CATEGORIES,
};

export const CATALOG_ITEM_WORKSHOPS = {
  title: CatalogRouteName.workshops,
  route: CatalogRoute.workshops,
  hideInDrawer: true,
  isStacking: true,
  substituteItem: CATALOG_ITEM_CATEGORIES,
};

export const CATALOG_CHILD_ITEMS: NavDrawerItem[] = [
  CATALOG_ITEM_CATEGORIES,
  CATALOG_ITEM_PRODUCTS,
  CATALOG_ITEM_INGREDIENTS,
  CATALOG_ITEM_SEMIMANUFACTURES,
  CATALOG_ITEM_MODIFIER_GROUPS,
  CATALOG_ITEM_MODIFIERS,
  CATALOG_ITEM_MENU,
  CATALOG_ITEM_SETTINGS,
  CATALOG_ITEM_WORKSHOPS,
];

export const PRODUCT_LIST_ROUTES: Record<ProductType, string> = {
  INGREDIENT: CatalogRoute.ingredients,
  MODIFIER: CatalogRoute.modifiers,
  PRODUCT: CatalogRoute.products,
  SEMIPRODUCT: CatalogRoute.semimanufactures,
};
