import { setMaxNumber } from '@utils';

export enum VALIDATION_ERROR_TYPE {
  required = 'required',
  email = 'email',
  minlength = 'minlength',
  maxlength = 'maxlength',
  exactLength = 'exactLength',
  invalidDate = 'invalidDate',
  invalidPhoneNumber = 'invalidPhoneNumber',
  invalidPhoneNumberV2 = 'invalidPhoneNumberV2',
  invalidPercentageNumber = 'invalidPercentageNumber',
  positiveNumber = 'positiveNumber',
  invalidCharacters = 'invalidCharacters',
  max = 'max',
}

export enum MIN_CHARACTERS {
  PHONE = 12,
  INN_LEGAL = 10,
  INN_INDIVIDUAL = 12,
  KPP = 9,
  NAME = 1,
  ADDRESS = 2,
  EMAIL = 5,
  DESCRIPTION = 1,
  ORGANIZATION = 1,
  FIRST_NAME = 2,
  MIDDLE_NAME = 2,
  LAST_NAME = 2,
  SNILS = 11,
  PRICE = 1,
  ACCOUNT_PASSWORD = 8,
}

export enum MAX_CHARACTERS {
  PHONE = 30,
  INN_LEGAL = 10,
  INN_INDIVIDUAL = 12,
  KPP = 9,
  NAME = 80,
  ADDRESS = 200,
  EMAIL = 320,
  DESCRIPTION = 5000,
  SHORT_DESCRIPTION = 500,
  ORGANIZATION = 80,
  FIRST_NAME = 100,
  MIDDLE_NAME = 100,
  LAST_NAME = 100,
  SNILS = 11,
  PRICE = 10,
  PRODUCT_NAME = 150,
  CUSTOMER_LOYALTY_CARD = 30,
  MODIFIER_GROUP_NAME = 150,
  TEXT_MODIFIER_NAME = 150,
  SUPPLIER_CONTACT_POINT = 100,
  STORE_NAME = 150,
  PAYMENT_SYSTEM_NAME = 150,
  VATIN = 12,
  OGRN = 15,
  OKPO = 10,
  POSTAL_CODE = 6,
  CEO = 300,
  SIGNER = 300,
  BANK_ACCOUNT = 20,
  BANK_NAME = 200,
  BIC = 9,
  CORRESPONDENT_ACCOUNT = 20,
  ACCOUNT_PASSWORD = 40,
  MARKETING_CLIENT_CARD = 13,
  POINTS_DESCRIPTION = 90,
  WORKSHOP = 150,
  DOC_NUMBER = 15,
}

export const EXACT_CHARACTERS = {
  VATIN: [10, 12] as const,
  OGRN: [13, 15] as const,
  OKPO: [8, 10] as const,
  KPP: 9,
  SNILS: 11,
} as const;

export enum MAX_INTEGER {
  QUANTITY = 10,
  PRIME_PRICE = 10,
  SURCHARGE = 10,
  SALE_PRICE = 10,
  BRUTTO = 10,
  NETTO = 10,
  LOSSES = 3,
  NUTRITIONAL = 10,
}

export enum MAX_FRACTIONAL {
  QUANTITY = 3,
  PRIME_PRICE = 2,
  SURCHARGE = 2,
  SALE_PRICE = 2,
  BRUTTO = 3,
  NETTO = 3,
  LOSSES = 0,
  NUTRITIONAL = 3,
}

export enum MAX_NUMBER {
  INVENTORY_QUANTITY = setMaxNumber(MAX_INTEGER.QUANTITY, MAX_FRACTIONAL.QUANTITY),
  LEAVING_QUANTITY = setMaxNumber(MAX_INTEGER.QUANTITY, MAX_FRACTIONAL.QUANTITY),
  ARRIVAL_QUANTITY = setMaxNumber(MAX_INTEGER.QUANTITY, MAX_FRACTIONAL.QUANTITY),
  ARRIVAL_AMOUNT_FOR_ONE = setMaxNumber(MAX_INTEGER.PRIME_PRICE, MAX_FRACTIONAL.PRIME_PRICE),
  ARRIVAL_AMOUNT = setMaxNumber(MAX_INTEGER.PRIME_PRICE, MAX_FRACTIONAL.PRIME_PRICE),
  MOVING_FACT_QUANTITY = setMaxNumber(MAX_INTEGER.QUANTITY, MAX_FRACTIONAL.QUANTITY),
}

export enum MIN_NUMBER {
  INVENTORY_QUANTITY = 0,
  LEAVING_QUANTITY = 0,
  ARRIVAL_QUANTITY = 0,
  ARRIVAL_AMOUNT_FOR_ONE = 0,
  ARRIVAL_AMOUNT = 0,
  MOVING_FACT_QUANTITY = 0,
}
