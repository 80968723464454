import { gql } from 'apollo-angular';

import { fragmentPage } from './mutations';

export const queryChartReportsExistsForOrganization = gql`
  query chartReportsExistsForOrganization {
    chartReportsExistsForOrganization
  }
`;

export const queryAbcReportsExist = gql`
  query abcReportsExist($storeIds: [UUID!]) {
    abcReportsExist(storeIds: $storeIds)
  }
`;

export const queryAbcReportV2 = gql`
  query abcReportV2($pageRequest: PageRequestInput, $input: AbcReportInput!, $sort: [AbcReportV2Sort!]) {
    abcReportV2(input: $input) {
      rows(pageRequest: $pageRequest, sort: $sort) {
        content {
          categories {
            id
            name
          }
          name
          productType
          profit
          profitPercent
          profitPercentOutOfTotal
          quantity
          revenue
          revenuePercentOutOfTotal
          unitType
        }
        ...Page
      }
      total {
        percentageProfit
        percentageRevenue
        profit
        quantity
        revenue
      }
    }
  }
  ${fragmentPage}
`;
