import { TemplateRef } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { Drop } from '@app/shared/directive/drag-and-drop/drag-and-drop.types';
import { ScrollVisibilityEvent } from '@app/shared/directive/scroll-visibility/scroll-visibility.directive';

export interface TableConfig {
  usableWidth?: string;
  columnsConfig: ColumnConfig[];
  selectable: boolean;
  isHideHeader?: boolean;
  stickyHeader?: boolean;
  hasGroupHeader?: boolean;
  resizable?: boolean;
  groupHeader?: GroupHeader[];
  headerBackgroundColor?: string;
  expandable?: boolean;
  expandableDue?: number;
  dragAndDropOptions?: TableDragAndDropConfig;
  dragAndDropColumns?: boolean;
  totalRows?: Row[];
  enumerable?: TableEnumerableConfig;
  emptyTitle?: string;
  infiniteLoadConfig?: {
    loadMoreItems: (up?: boolean, keepPage?: boolean) => void;
    onRowHidden: (event: RowVisibilityDirection<EntityWithId>) => void;
    onRowVisible: (event: RowVisibilityDirection<EntityWithId>) => void;
    onRowHeightInited: (event: RowHeightEvent<EntityWithId>) => void;
  };
}

export type GroupHeader = {
  id: string;
  title: string;
  group: string[];
  columnColor?: boolean;
};

export type TableInfiniteLoadingState = {
  reloading: boolean;
  canLoad: boolean;
  canLoadUp: boolean;
  loadingUp: boolean;
  loadingDown: boolean;
  offsetTop: number;
  offsetBottom: number;
  loadedDown?: boolean;
};

export type EntityWithId = {
  id: string;
  [key: string]: unknown;
};

export interface Row<T extends EntityWithId | null = EntityWithId, C = unknown> {
  rowId?: string;
  columnsData: Column<C>[];
  children?: Row<T>[];
  expanded?: boolean;
  selected?: HeaderCheckboxState;
  highlighted?: boolean;
  lazyChildren?: Observable<Row<T>[]>;
  data?: T;
  rowDataId?: string;
  refreshChildren$?: ReplaySubject<boolean>;
  hideExpand?: boolean;
  isGroupedRow?: boolean;
  enumerator?: number;
  skipEnumerator?: boolean;
  rowColor?: boolean;
  rowColorBackground?: string;
  isCategoryRow?: boolean;
}

export interface Column<C = unknown> {
  content?: string;
  subContent?: string;
  template?: TemplateRef<unknown>;
  column: string;
  color?: boolean;
  type?: 'text' | 'numeric';
  data?: C;
  isTransparentBorder?: boolean;
  isTopBorder?: boolean;
}

export interface ColumnConfig {
  name: string;
  title: string;
  sortable: boolean;
  isSorting?: boolean;
  isEdit?: boolean;
  hidden?: boolean;
  pinned?: boolean;
  required?: boolean;
  group?: string;
  columnColor?: boolean;
  width?: string | [string, string];
  sorting?: SortEnum | null;
  defaultSort?: SortEnum;
  order?: number;
  align?: AlignSetting;
  template?: TemplateRef<unknown>;
  tooltip?: string;
}

export interface TableDragAndDropConfig {
  enable: boolean;
  dragAndDropType: DragAndDropType;
}

export interface TableEnumerableConfig {
  enabled: boolean;
  children: boolean;
  showLast?: boolean;
}

export enum SortEnum {
  DESC = 'DESC', // в порядке убывания
  ASC = 'ASC', // в порядке возрастания
}

export enum DragAndDropType {
  levelToLevel = 'levelToLevel',
  insideLevel = 'insideLevel',
}

// export interface DropRow {
//   dropRow: CdkDragDrop<Row>;
// }

export interface DropResultEvent {
  onPositionOf: Row;
  dropRowEvent: Drop<Row>;
  firstInList: boolean;
}

export type HeaderCheckboxState = true | false | 'indeterminate';

export const MAX_COLUMN_WIDTH = 214;
export const MIN_COLUMN_WIDTH = 64;
export const SELECT_COLUMN_WIDTH = 40;
export const ROW_LEVEL_PADDING = 32;
export const TABLE_OFFSET_MARGIN = 32;

export type RowVisibilityDirection<T extends EntityWithId | null = EntityWithId> = { row: Row<T>; visibilityEvent: ScrollVisibilityEvent };
export type RowHeightEvent<T extends EntityWithId | null = EntityWithId> = { row: Row<T>; height: number };
